import './ModalView.css';


export function ModalView({isVisible, hide, children}){

    if(isVisible){
        return (
            <div className='ModalView' onClick={hide}>
                <div className='ModalView_body'>
                    {children}
                </div>
            </div>
        )
    }

    return (
        <></>
    )
}