import { useState } from 'react';
import './AddCycle.css';
import DateObject from 'react-date-object';
import { db } from "./firebase.js";
import { Basic } from './Basic.js';


export function AddCycle({ onClose, cycles, trainee }) {

    const State = {
        Name: 'Name',
        Start: 'Start',
        End: 'End',
        Save: 'Save'
    };

    const [state, setState] = useState(State.Name);
    const [date, setDate] = useState(new DateObject());

    const [name, setName] = useState("");
    const [debut, setDebut] = useState(new Date())
    const [fin, setFin] = useState(new Date())

    function previousYear() {
        setDate(new DateObject({
            year: date.year - 1,
            month: date.month.number,
            day: 1
        }))
    }

    function nextYear() {
        setDate(new DateObject({
            year: date.year + 1,
            month: date.month.number,
            day: 1
        }))
    }

    function cancel() {
        setState(State.Name);
        setName("");
        onClose();
    }

    function save() {
        db.collection("Trainees").doc(trainee.id).collection("Cycles").add({
            name: name,
            debut: (new DateObject(debut)).format(),
            fin: (new DateObject(fin)).format()
        })
        setState(State.Name);
        setName("");
        onClose();
    }

    function goToName() {
        console.log(name)
        setState(State.Name)
        console.log(name)
    }

    function goToStart() {
        if (name === "") {
            alert("Vous devez donner un nom au nouveau cycle.");
        } else {
            setState(State.Start)
        }
    }

    function goToEnd(dd) {
        setDebut(new Date(dd));
        setState(State.End);
    }

    function goBackToEnd() {
        setState(State.End);
    }

    function goToSave(dd) {
        setFin(new Date(dd));
        setState(State.Save);
    }

    function CalendarWeek(props) {
        let item = props.item;
        if (item != null) {
            if (item.free) {
                return (
                    <td className='AddCycle_star_week_cell' onClick={() => props.func(item.start)}>
                        Semaine {item.number} : <br />
                        du {item.start.toLocaleDateString()} <br />
                        au {item.end.toLocaleDateString()}
                    </td>
                )
            } else {
                return (
                    <td className='AddCycle_star_week_cell_notfree'>
                        Semaine {item.number} : <br />
                        du {item.start.toLocaleDateString()} <br />
                        au {item.end.toLocaleDateString()}
                    </td>
                )
            }
        }

        return (
            <td>
            </td>
        )
    }

    function CalendarStart() {
        let d = new DateObject(date);

        //--------Recuperer le premier jour de la premiere semaine de l'année------
        let year_start = new Date(Date.UTC(d.toDate().getUTCFullYear(), 0, 1));
        let day_in_week = year_start.getDay();
        if (day_in_week === 0)
            day_in_week = 7;
        day_in_week--;

        let first_day_of_week_year = new Date(Date.UTC(d.toDate().getUTCFullYear(), 0, 1 - (day_in_week)));

        if (day_in_week > 3) {
            first_day_of_week_year = new Date(Date.UTC(d.toDate().getUTCFullYear(), 0, 8 - (day_in_week)));
        }
        //--------------------------------------------------------------------------

        //--------Recuperer le premier jour de la premiere semaine de l'année suivante------
        let next_year_start = new Date(Date.UTC(d.toDate().getUTCFullYear() + 1, 0, 1));
        day_in_week = next_year_start.getDay();
        if (day_in_week === 0)
            day_in_week = 7;
        day_in_week--;

        let first_day_of_week_next_year = new Date(Date.UTC(d.toDate().getUTCFullYear() + 1, 0, 1 - (day_in_week)));

        if (day_in_week > 3) {
            first_day_of_week_next_year = new Date(Date.UTC(d.toDate().getUTCFullYear() + 1, 0, 8 - (day_in_week)));
        }
        //--------------------------------------------------------------------------
        //Corps du calendrier
        let calendar = [];
        let ligne_calendar = [];
        let week_number = 1;
        let first_day_of_week = first_day_of_week_year;
        let i = 0;

        while (first_day_of_week < first_day_of_week_next_year) {
            if (i >= 10) {
                calendar.push(ligne_calendar);
                ligne_calendar = [];
                i = 0;
            }

            let week_start = first_day_of_week;
            let week_end = new Date(Date.UTC(first_day_of_week.getUTCFullYear(), first_day_of_week.getUTCMonth(), first_day_of_week.getUTCDate() + 6));

            let free_week = true;
            for (let j = 0; j < cycles.length && free_week; j++) {
                let cycle_start = new Date(cycles[j].debut);
                let cycle_end = new Date(cycles[j].fin);
                if (cycle_start <= week_start && cycle_end >= week_end) {
                    free_week = false;
                }
            }

            ligne_calendar.push({
                number: week_number,
                start: week_start,
                end: week_end,
                free: free_week
            });

            first_day_of_week = new Date(Date.UTC(first_day_of_week.getUTCFullYear(), first_day_of_week.getUTCMonth(), first_day_of_week.getUTCDate() + 7));
            week_number++;
            i++;
        }

        for (let j = i; j < 10; j++) {
            ligne_calendar.push(null);
        }
        calendar.push(ligne_calendar);

        const iterateItem = (items) => {
            return items.map((item) => (
                <tr key={(Math.random() + 1).toString(36).substring(7)}>
                    <CalendarWeek item={item[0]} func={goToEnd} />
                    <CalendarWeek item={item[1]} func={goToEnd} />
                    <CalendarWeek item={item[2]} func={goToEnd} />
                    <CalendarWeek item={item[3]} func={goToEnd} />
                    <CalendarWeek item={item[4]} func={goToEnd} />
                    <CalendarWeek item={item[5]} func={goToEnd} />
                    <CalendarWeek item={item[6]} func={goToEnd} />
                    <CalendarWeek item={item[7]} func={goToEnd} />
                    <CalendarWeek item={item[8]} func={goToEnd} />
                    <CalendarWeek item={item[9]} func={goToEnd} />
                </tr>
            ))
        }

        let contentCalendar = iterateItem(calendar);

        return (
            <table className='AddCycle_star_table'>
                <tbody>
                    <tr className='AddCycle_star_year_row'><td colSpan={10}>
                        <button onClick={previousYear}> {"<<"} </button>
                        {d.year}
                        <button onClick={nextYear}> {">>"} </button>
                    </td></tr>
                    {contentCalendar}
                </tbody>
            </table>
        )
    }

    function CalendarEnd() {
        let d = new DateObject(date);

        //--------Recuperer le premier jour de la premiere semaine de l'année------
        let year_start = new Date(Date.UTC(d.toDate().getUTCFullYear(), 0, 1));
        let day_in_week = year_start.getDay();
        if (day_in_week === 0)
            day_in_week = 7;
        day_in_week--;

        let first_day_of_week_year = new Date(Date.UTC(d.toDate().getUTCFullYear(), 0, 1 - (day_in_week)));

        if (day_in_week > 3) {
            first_day_of_week_year = new Date(Date.UTC(d.toDate().getUTCFullYear(), 0, 8 - (day_in_week)));
        }
        //--------------------------------------------------------------------------

        //--------Recuperer le premier jour de la premiere semaine de l'année suivante------
        let next_year_start = new Date(Date.UTC(d.toDate().getUTCFullYear() + 1, 0, 1));
        day_in_week = next_year_start.getDay();
        if (day_in_week === 0)
            day_in_week = 7;
        day_in_week--;

        let first_day_of_week_next_year = new Date(Date.UTC(d.toDate().getUTCFullYear() + 1, 0, 1 - (day_in_week)));

        if (day_in_week > 3) {
            first_day_of_week_next_year = new Date(Date.UTC(d.toDate().getUTCFullYear() + 1, 0, 8 - (day_in_week)));
        }
        //--------------------------------------------------------------------------
        //Corps du calendrier

        let cycle_limit = null;
        for (let j = 0; j < cycles.length; j++) {
            let cycle_start = new Date(cycles[j].debut);
            if (cycle_start > debut) {
                if (cycle_limit == null || cycle_limit > cycle_start)
                    cycle_limit = cycle_start;
            }
        }

        let calendar = [];
        let ligne_calendar = [];
        let week_number = 1;
        let first_day_of_week = first_day_of_week_year;
        let i = 0;

        while (first_day_of_week < first_day_of_week_next_year) {
            if (i >= 10) {
                calendar.push(ligne_calendar);
                ligne_calendar = [];
                i = 0;
            }

            let week_start = first_day_of_week;
            let week_end = new Date(Date.UTC(first_day_of_week.getUTCFullYear(), first_day_of_week.getUTCMonth(), first_day_of_week.getUTCDate() + 6));

            let free_week = true;
            if (week_end < debut || (cycle_limit != null && week_start >= cycle_limit)) {
                free_week = false;
            }

            ligne_calendar.push({
                number: week_number,
                start: week_start,
                end: week_end,
                free: free_week
            });

            first_day_of_week = new Date(Date.UTC(first_day_of_week.getUTCFullYear(), first_day_of_week.getUTCMonth(), first_day_of_week.getUTCDate() + 7));
            week_number++;
            i++;
        }

        for (let j = i; j < 10; j++) {
            ligne_calendar.push(null);
        }
        calendar.push(ligne_calendar);

        const iterateItem = (items) => {
            return items.map((item) => (
                <tr key={(Math.random() + 1).toString(36).substring(7)}>
                    <CalendarWeek item={item[0]} func={goToSave} />
                    <CalendarWeek item={item[1]} func={goToSave} />
                    <CalendarWeek item={item[2]} func={goToSave} />
                    <CalendarWeek item={item[3]} func={goToSave} />
                    <CalendarWeek item={item[4]} func={goToSave} />
                    <CalendarWeek item={item[5]} func={goToSave} />
                    <CalendarWeek item={item[6]} func={goToSave} />
                    <CalendarWeek item={item[7]} func={goToSave} />
                    <CalendarWeek item={item[8]} func={goToSave} />
                    <CalendarWeek item={item[9]} func={goToSave} />
                </tr>
            ))
        }

        let contentCalendar = iterateItem(calendar);

        return (
            <table className='AddCycle_star_table'>
                <tbody>
                    <tr className='AddCycle_star_year_row'><td colSpan={10}>
                        <button onClick={previousYear}> {"<<"} </button>
                        {d.year}
                        <button onClick={nextYear}> {">>"} </button>
                    </td></tr>
                    {contentCalendar}
                </tbody>
            </table>
        )
    }

    if (state === State.Start) {
        return (
            <Basic title="Première semaine du nouveau cycle :" hide={goToName} buttons={
                <>
                </>
            }>
                <tr>
                    <td>
                        <CalendarStart />
                    </td>
                </tr>
            </Basic>
        )
    }

    if (state === State.End) {
        return (
            <Basic title="Dernière semaine du nouveau cycle :" hide={goToStart} buttons={
                <>
                </>
            }>
                <tr>
                    <td>
                    <CalendarEnd />
                    </td>
                </tr>
            </Basic>
        )
    }

    if (state === State.Save) {
        let f = new DateObject(fin)
        let a = new DateObject({
            year: f.year,
            month: f.month.number,
            day: f.day + 6
        });
        return (
            <Basic title="Enregistrer le nouveau cycle :" hide={goBackToEnd} buttons={
                <button onClick={() => save()}><span className="material-symbols-outlined">save</span></button>
            }>
                <tr>
                    <td>
                        {name} <br />
                        du {debut.toLocaleDateString()} au {a.toDate().toLocaleDateString()}
                    </td>
                </tr>
            </Basic>

            /*
            <div className='AddCycle'>
                <table className='AddTrainee_table'>
                    <tbody>
                        <tr><td colSpan={2} className='AddTrainee_title'>
                            Enregistrer le nouveau cycle :
                        </td></tr>
                        <tr>
                            <td colSpan={2}>
                                {name} <br />
                                du {debut.toLocaleDateString()} au {a.toDate().toLocaleDateString()}
                            </td>
                        </tr>
                        <tr>
                            <td colSpan={2} className='AddTrainee_button'>
                                <button onClick={() => cancel()}><span className="material-symbols-outlined">close</span></button>
                                <button onClick={() => save()}><span className="material-symbols-outlined">save</span></button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>*/
        )
    }

    return (
        <Basic title="Choisir le nom du nouveau cycle :" hide={cancel} buttons={
            <>
                <button onClick={() => goToStart()}><span className="material-symbols-outlined">arrow_forward</span></button>
            </>
        }>
            <>
                <tr>
                    <td className='AddTrainee_form_left'>Nom : </td>
                    <td className='AddTrainee_form_right'><input type='text' name='newName' onChange={e => setName(e.target.value)} value={name}/></td>
                </tr>
            </>
        </Basic>
    )
}