
import './Basic.css'
import { useEffect, useRef } from 'react';

export function Basic({ title, hide, buttons, children }) {

    const listRef = useRef();
    useEffect(() => {
        let scrollPosition = localStorage.getItem("ScrollPosition_Basic");
        if (scrollPosition) {
            listRef.current.scrollTo(0, scrollPosition, "auto");
        } else {
            listRef.current.scrollTo(0, (25 + (6 * 50)), "auto");
        }

        const unloadCallback = () => {
            localStorage.setItem("ScrollPosition_Basic", 0);
        }
        window.addEventListener("beforeunload", unloadCallback);
        return async () => {
        window.removeEventListener("beforeunload", unloadCallback);
        }
    }, []);

    function newPosition(y) {
        localStorage.setItem("ScrollPosition_Basic", y);
    }

    function back() {
        localStorage.setItem("ScrollPosition_Basic", 0);
        hide();
    }

    return (
        <table className='Basic_table'>
            <tbody>
                <tr>
                    <td className='Basic_title'>{title}<span onClick={back} className="material-symbols-outlined Basic_title_left">arrow_back</span></td>
                </tr>
                <tr>
                    <td className='Basic_body'>
                        <div className='Basic_body_div' ref={listRef} onScroll={({ target }) => newPosition(target.scrollTop)}>
                            <table  className='Basic_body_table'>
                                <tbody>
                                    {children}
                                </tbody>
                            </table>
                        </div>
                    </td>
                </tr>

                <tr><td className='Session_button'>
                    <div className='Session_button_div'>
                        {buttons}
                    </div>
                </td></tr>
            </tbody>
        </table>
    )
}

export function BasicAdd({ title, hide, add, buttons, children }) {

    return (
        <table className='Basic_table'>
            <tbody>
                <tr>
                    <td className='Basic_title'>{title}<span onClick={hide} className="material-symbols-outlined Basic_title_left">arrow_back</span><span onClick={add} className="material-symbols-outlined Basic_title_right">add</span></td>
                </tr>
                <tr>
                    <td className='Basic_body'>
                        <div className='Basic_body_div'>
                            <table  className='Basic_body_table'>
                                <tbody>
                                    {children}
                                </tbody>
                            </table>
                        </div>
                    </td>
                </tr>

                <tr><td className='Session_button'>
                    <div className='Session_button_div'>
                        {buttons}
                    </div>
                </td></tr>
            </tbody>
        </table>
    )
}

export function BasicInput({ title, setTitle, hide, buttons, children }) {

    return (
        <table className='Basic_table'>
            <tbody>
                <tr>
                    <td className='Basic_title'>
                    <input type='text' className='Basic_title_input' value={title} onChange={(e) => setTitle(e.target.value)} />
                        <span onClick={hide} className="material-symbols-outlined Basic_title_left">arrow_back</span>
                    </td>
                </tr>
                <tr>
                    <td className='Basic_body'>
                        <div className='Basic_body_div'>
                            <table  className='Basic_body_table'>
                                <tbody>
                                    {children}
                                </tbody>
                            </table>
                        </div>
                    </td>
                </tr>

                <tr><td className='Session_button'>
                    <div className='Session_button_div'>
                        {buttons}
                    </div>
                </td></tr>
            </tbody>
        </table>
    )
}