import { useState } from 'react'
import './Exercices.css'
import { Basic, BasicAdd, BasicInput } from './Basic'
import { db, auth } from "./firebase.js";
import Select from 'react-select';
import { AgeFilter, SupportFilter, CycleFilter, PhysicFilter, TechnicFilter, TacticFilter, MentalFilter } from './Data'
import { Editor } from 'react-draft-wysiwyg';
import '../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import htmlToDraft from 'html-to-draftjs';
import draftToHtml from 'draftjs-to-html';
import { ContentState, convertToRaw, EditorState } from 'draft-js';
import { timeToString } from './Librairy.js';

export function ExercicesView({ hide, exercices }) {

    function ViewSizer() {
        return (
            <ExercicesColonne hide={hide} exercices={exercices} />
        )
    }

    return (
        <ViewSizer />
    )
}


export function ExercicesColonne({ hide, exercices }) {
    const Menu = {
        Exercices: 'Exercices',
        Filters: 'Filters',
        Exercice: 'Exercice',
        AddExercice: 'AddExercice',
        EditExercice: 'EditExercice',
    };

    const [state, setState] = useState(Menu.Exercices);
    const [exercice, setExercice] = useState(null);
    const [exercice_to_edit, setExerciceToEdit] = useState(null);

    //const [filtered_exercices, setFilteredExercices] = useState(exercices);

    const [ages, setAges] = useState([]);
    const [cycles, setCycles] = useState([]);
    const [supports, setSupports] = useState([]);
    const [technics, setTechnics] = useState([]);
    const [physics, setPhysics] = useState([]);
    const [tactics, setTactics] = useState([]);
    const [mentals, setMentals] = useState([]);

    //function filter() {
    let filtered_exercices = [];
    exercices.forEach((e) => {
        let ok = true;
        ages.forEach(a => {
            if (e.ages.indexOf(a) === -1) {
                ok = false;
            }
        })

        supports.forEach(a => {
            if (e.supports.indexOf(a) === -1) {
                ok = false;
            }
        })

        cycles.forEach(a => {
            if (e.cycles.indexOf(a) === -1) {
                ok = false;
            }
        })

        technics.forEach(a => {
            if (e.technics.indexOf(a) === -1) {
                ok = false;
            }
        })

        physics.forEach(a => {
            if (e.physics.indexOf(a) === -1) {
                ok = false;
            }
        })

        tactics.forEach(a => {
            if (e.tactics.indexOf(a) === -1) {
                ok = false;
            }
        })

        mentals.forEach(a => {
            if (e.mentals.indexOf(a) === -1) {
                ok = false;
            }
        })

        if (ok) {
            filtered_exercices.push(e);
        }
    })
    //setFilteredExercices(f);
    //}

    /*useEffect(() => {
        filter();
    }, [filtered_exercices, setFilteredExercices, filter]);*/

    function resetFilters() {
        setAges([]);
        setCycles([]);
        setSupports([]);
        setTechnics([]);
        setTactics([]);
        setPhysics([]);
        setMentals([]);
    }

    function selectAge(i) {
        setAges([i])
    }

    function checkAge(i) {
        let a = ages.indexOf(i);
        let t = [...ages];
        if (a < 0) {
            t.push(i);
        } else {
            t.splice(a, 1);
        }
        setAges(t);
    }

    function selectCycle(i) {
        setCycles([i])
    }

    function checkCycle(i) {
        let a = cycles.indexOf(i);
        let t = [...cycles];
        if (a < 0) {
            t.push(i);
        } else {
            t.splice(a, 1);
        }
        setCycles(t);
    }

    function selectSupport(i) {
        setSupports([i])
    }

    function checkSupport(i) {
        let a = supports.indexOf(i);
        let t = [...supports];
        if (a < 0) {
            t.push(i);
        } else {
            t.splice(a, 1);
        }
        setSupports(t);
    }

    function selectTechnic(i) {
        setTechnics([i])
    }

    function checkTechnic(i) {
        let a = technics.indexOf(i);
        let t = [...technics];
        if (a < 0) {
            t.push(i);
        } else {
            t.splice(a, 1);
        }
        setTechnics(t);
    }

    function selectPhysic(i) {
        setPhysics([i])
    }

    function checkPhysic(i) {
        let a = physics.indexOf(i);
        let t = [...physics];
        if (a < 0) {
            t.push(i);
        } else {
            t.splice(a, 1);
        }
        setPhysics(t);
    }

    function selectTactic(i) {
        setTactics([i])
    }

    function checkTactic(i) {
        let a = tactics.indexOf(i);
        let t = [...tactics];
        if (a < 0) {
            t.push(i);
        } else {
            t.splice(a, 1);
        }
        setTactics(t);
    }

    function selectMental(i) {
        setMentals([i])
    }

    function checkMental(i) {
        let a = mentals.indexOf(i);
        let t = [...mentals];
        if (a < 0) {
            t.push(i);
        } else {
            t.splice(a, 1);
        }
        setMentals(t);
    }

    function toExercices() {
        setState(Menu.Exercices);
    }

    function toAddExercice() {
        setState(Menu.AddExercice);
    }

    function toExercice(exo) {
        setExercice(exo);
        setState(Menu.Exercice);
    }

    function toEditExercice(exo) {

        const minutes = exo.time % 60;
        const hours = (exo.time - minutes)/60;

        const blocksFromHtml = htmlToDraft(exo.situation);
        const { contentBlocks, entityMap } = blocksFromHtml;
        const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
        const editorState = EditorState.createWithContent(contentState);

        function iteratCat(list, list2){
            let res = [];
            list.forEach((e) => {
                if(list2.includes(e.value)){
                    res.push(e);
                }
            })
            return res;
        }

        let exo_temp = {
            id: exo.id,
            name: exo.name,
            minutes: minutes,
            hours: hours,
            objectif: exo.objectif,
            ages: iteratCat(AgeFilter, exo.ages),
            supports: iteratCat(SupportFilter, exo.supports),
            cycles: iteratCat(CycleFilter, exo.cycles),
            physics: iteratCat(PhysicFilter, exo.physics),
            technics: iteratCat(TechnicFilter, exo.technics),
            tactics: iteratCat(TacticFilter, exo.tactics),
            mentals: iteratCat(MentalFilter, exo.mentals),
            situation: editorState
        }
        setExerciceToEdit(exo_temp);
        setState(Menu.EditExercice);
    }


    function toFilters() {
        setState(Menu.Filters);
    }

    function FiltersView() {
        const iterateAge = (list) => {
            return list.map((e) => (
                <tr key={e.value}>
                    {
                        ages.includes(e.value) ?
                            <td className='ExercicesColonneFilter_cat'><div onClick={() => checkAge(e.value)} className='ExercicesColonneFilter_selected'></div>&nbsp;&nbsp;<span onClick={() => selectAge(e.value)}>{e.label}</span></td>
                            :
                            <td className='ExercicesColonneFilter_cat'><div onClick={() => checkAge(e.value)} className='ExercicesColonneFilter_notselected' ></div>&nbsp;&nbsp;<span onClick={() => selectAge(e.value)}>{e.label}</span></td>
                    }
                </tr>
            ))
        }

        const iterateCycle = (list) => {
            return list.map((e) => (
                <tr key={e.value}>
                    {
                        cycles.includes(e.value) ?
                            <td className='ExercicesColonneFilter_cat'><div onClick={() => checkCycle(e.value)} className='ExercicesColonneFilter_selected'></div>&nbsp;&nbsp;<span onClick={() => selectCycle(e.value)}>{e.label}</span></td>
                            :
                            <td className='ExercicesColonneFilter_cat'><div onClick={() => checkCycle(e.value)} className='ExercicesColonneFilter_notselected' ></div>&nbsp;&nbsp;<span onClick={() => selectCycle(e.value)}>{e.label}</span></td>
                    }
                </tr>
            ))
        }

        const iterateSupport = (list) => {
            return list.map((e) => (
                <tr key={e.value}>
                    {
                        supports.includes(e.value) ?
                            <td className='ExercicesColonneFilter_cat'><div onClick={() => checkSupport(e.value)} className='ExercicesColonneFilter_selected'></div>&nbsp;&nbsp;<span onClick={() => selectSupport(e.value)}>{e.label}</span></td>
                            :
                            <td className='ExercicesColonneFilter_cat'><div onClick={() => checkSupport(e.value)} className='ExercicesColonneFilter_notselected' ></div>&nbsp;&nbsp;<span onClick={() => selectSupport(e.value)}>{e.label}</span></td>
                    }
                </tr>
            ))
        }

        const iterateTechnic = (list) => {
            return list.map((e) => (
                <tr key={e.value}>
                    {
                        technics.includes(e.value) ?
                            <td className='ExercicesColonneFilter_cat'><div onClick={() => checkTechnic(e.value)} className='ExercicesColonneFilter_selected'></div>&nbsp;&nbsp;<span onClick={() => selectTechnic(e.value)}>{e.label}</span></td>
                            :
                            <td className='ExercicesColonneFilter_cat'><div onClick={() => checkTechnic(e.value)} className='ExercicesColonneFilter_notselected' ></div>&nbsp;&nbsp;<span onClick={() => selectTechnic(e.value)}>{e.label}</span></td>
                    }
                </tr>
            ))
        }

        const iteratePhysic = (list) => {
            return list.map((e) => (
                <tr key={e.value}>
                    {
                        physics.includes(e.value) ?
                            <td className='ExercicesColonneFilter_cat'><div onClick={() => checkPhysic(e.value)} className='ExercicesColonneFilter_selected'></div>&nbsp;&nbsp;<span onClick={() => selectPhysic(e.value)}>{e.label}</span></td>
                            :
                            <td className='ExercicesColonneFilter_cat'><div onClick={() => checkPhysic(e.value)} className='ExercicesColonneFilter_notselected' ></div>&nbsp;&nbsp;<span onClick={() => selectPhysic(e.value)}>{e.label}</span></td>
                    }
                </tr>
            ))
        }

        const iterateTactic = (list) => {
            return list.map((e) => (
                <tr key={e.value}>
                    {
                        tactics.includes(e.value) ?
                            <td className='ExercicesColonneFilter_cat'><div onClick={() => checkTactic(e.value)} className='ExercicesColonneFilter_selected'></div>&nbsp;&nbsp;<span onClick={() => selectTactic(e.value)}>{e.label}</span></td>
                            :
                            <td className='ExercicesColonneFilter_cat'><div onClick={() => checkTactic(e.value)} className='ExercicesColonneFilter_notselected' ></div>&nbsp;&nbsp;<span onClick={() => selectTactic(e.value)}>{e.label}</span></td>
                    }
                </tr>
            ))
        }

        const iterateMental = (list) => {
            return list.map((e) => (
                <tr key={e.value}>
                    {
                        mentals.includes(e.value) ?
                            <td className='ExercicesColonneFilter_cat'><div onClick={() => checkMental(e.value)} className='ExercicesColonneFilter_selected'></div>&nbsp;&nbsp;<span onClick={() => selectMental(e.value)}>{e.label}</span></td>
                            :
                            <td className='ExercicesColonneFilter_cat'><div onClick={() => checkMental(e.value)} className='ExercicesColonneFilter_notselected' ></div>&nbsp;&nbsp;<span onClick={() => selectMental(e.value)}>{e.label}</span></td>
                    }
                </tr>
            ))
        }

        return (
            <Basic title="Filtrer" buttons={<>
                <button title="Filtrer les exercices" onClick={resetFilters} ><span className="material-symbols-outlined">replay</span></button>
            </>} hide={toExercices}>
                <tr><td className='ExercicesColonneFilter_title'>Ages : </td></tr>
                {iterateAge(AgeFilter)}
                <tr><td className='ExercicesColonneFilter_title'>Supports : </td></tr>
                {iterateSupport(SupportFilter)}
                <tr><td className='ExercicesColonneFilter_title'>Cycles : </td></tr>
                {iterateCycle(CycleFilter)}
                <tr><td className='ExercicesColonneFilter_title'>Techniques : </td></tr>
                {iterateTechnic(TechnicFilter)}
                <tr><td className='ExercicesColonneFilter_title'>Physiques : </td></tr>
                {iteratePhysic(PhysicFilter)}
                <tr><td className='ExercicesColonneFilter_title'>Tactiques : </td></tr>
                {iterateTactic(TacticFilter)}
                <tr><td className='ExercicesColonneFilter_title'>Mentals : </td></tr>
                {iterateMental(MentalFilter)}
                <tr><td className='ExercicesColonneFilter_void'></td></tr>
            </Basic>
        )
    }

    function AddView() {
        const [name, setName] = useState("Nouvel Exercice");
        const [objectif, setObjectif] = useState("");
        const [hours, setHours] = useState(0);
        const [minutes, setMinutes] = useState(5);

        const [add_ages, setAddAges] = useState([]);
        const [add_cycles, setAddCycles] = useState([]);
        const [add_supports, setAddSupports] = useState([]);
        const [add_technics, setAddTechnics] = useState([]);
        const [add_physics, setAddPhysics] = useState([]);
        const [add_tactics, setAddTactics] = useState([]);
        const [add_mentals, setAddMentals] = useState([]);
        const [situation, setSituation] = useState(EditorState.createEmpty());


        function save() {
            let a = [];
            add_ages.forEach((e) => {
                a.push(e.value);
            })

            let c = [];
            add_cycles.forEach((e) => {
                c.push(e.value);
            })

            let s = [];
            add_supports.forEach((e) => {
                s.push(e.value);
            })

            let te = [];
            add_technics.forEach((e) => {
                te.push(e.value);
            })

            let p = [];
            add_physics.forEach((e) => {
                p.push(e.value);
            })

            let ta = [];
            add_tactics.forEach((e) => {
                ta.push(e.value);
            })

            let me = [];
            add_mentals.forEach((e) => {
                me.push(e.value);
            })

            let e = {
                name: name,
                time: (hours * 60) + minutes,
                objectif: objectif,
                ages: a,
                cycles: c,
                supports: s,
                technics: te,
                physics: p,
                tactics: ta,
                mentals: me,
                situation: draftToHtml(convertToRaw(situation.getCurrentContent()))
            }

            //console.log(e);
            db.collection("Users").doc(auth.currentUser.uid).collection("Exercices").add(e);

            toExercices();
        }

        return (
            <BasicInput
                hide={toExercices}
                title={name}
                setTitle={setName}
                buttons={
                    <button onClick={() => { save() }}><span className="material-symbols-outlined">save</span></button>
                }
            >
                <tr>
                    <td className='AddExerciceView_subtitle'>Durée : </td>
                </tr>
                <tr>
                    <td className='AddExerciceView_info'>
                        <select value={hours} onChange={(e) => setHours(parseInt(e.target.value))}>
                            <option value={0}>0h</option>
                            <option value={1}>1h</option>
                            <option value={2}>2h</option>
                            <option value={3}>3h</option>
                            <option value={4}>4h</option>
                            <option value={5}>5h</option>
                        </select>
                        <select value={minutes} onChange={(e) => setMinutes(parseInt(e.target.value))}>
                            <option value={0}>0min</option>
                            <option value={5}>5min</option>
                            <option value={10}>10min</option>
                            <option value={15}>15min</option>
                            <option value={20}>20min</option>
                            <option value={25}>25min</option>
                            <option value={30}>30min</option>
                            <option value={35}>35min</option>
                            <option value={40}>40min</option>
                            <option value={45}>45min</option>
                            <option value={50}>50min</option>
                            <option value={55}>55min</option>
                        </select>
                    </td>
                </tr>

                <tr> <td className='AddExerciceView_emptyline'></td> </tr>
                <tr>
                    <td className='AddExerciceView_subtitle'>Objectif : </td>
                </tr>
                <tr>
                    <td className='AddExerciceView_info'>
                        <textarea className='AddExerciceView_objectif' title='Objectif' onChange={(e) => setObjectif(e.target.value)} value={objectif} />
                    </td>
                </tr>
                <tr> <td className='AddExerciceView_emptyline'></td> </tr>
                <tr>
                    <td className='AddExerciceView_subtitle'>Catégorie d'age :</td>
                </tr>
                <tr>
                    <td className='AddExerciceView_info'>
                        <Select
                            name="ages"
                            placeholder="Ages"
                            closeMenuOnSelect={false}
                            defaultValue={add_ages}
                            options={AgeFilter}
                            onChange={o => { setAddAges(o) }}
                            isMulti
                        />
                    </td>
                </tr>

                <tr> <td className='AddExerciceView_emptyline'></td> </tr>
                <tr>
                    <td className='AddExerciceView_subtitle'>Ressource(s) travaillée(s) :</td>
                </tr>
                <tr>
                    <td className='AddExerciceView_info'>
                        - Physiques :
                        <Select
                            name="physiques"
                            placeholder="Physiques"
                            closeMenuOnSelect={false}
                            defaultValue={add_physics}
                            options={PhysicFilter}
                            onChange={o => { setAddPhysics(o) }}
                            isMulti
                        />
                    </td>
                </tr>
                <tr>
                    <td className='AddExerciceView_info'>
                        - Techniques :
                        <Select
                            name="techniques"
                            placeholder="Techniques"
                            closeMenuOnSelect={false}
                            defaultValue={add_technics}
                            options={TechnicFilter}
                            onChange={o => { setAddTechnics(o) }}
                            isMulti
                        />
                    </td>
                </tr>
                <tr>
                    <td className='AddExerciceView_info'>
                        - Tactiques :
                        <Select
                            name="tactiques"
                            placeholder="Tactiques"
                            closeMenuOnSelect={false}
                            defaultValue={add_tactics}
                            options={TacticFilter}
                            onChange={o => { setAddTactics(o) }}
                            isMulti
                        />
                    </td>
                </tr>
                <tr>
                    <td className='AddExerciceView_info'>
                        - Mental :
                        <Select
                            name="mentals"
                            placeholder="Mentals"
                            closeMenuOnSelect={false}
                            defaultValue={add_mentals}
                            options={MentalFilter}
                            onChange={o => { setAddMentals(o) }}
                            isMulti
                        />
                    </td>
                </tr>

                <tr> <td className='AddExerciceView_emptyline'></td> </tr>
                <tr>
                    <td className='AddExerciceView_subtitle'>Cycle de préparation :</td>
                </tr>
                <tr>
                    <td className='AddExerciceView_info'>
                        <Select
                            name="cycles"
                            placeholder="Cycles"
                            closeMenuOnSelect={false}
                            defaultValue={add_cycles}
                            options={CycleFilter}
                            onChange={o => { setAddCycles(o) }}
                            isMulti
                        />
                    </td>
                </tr>

                <tr> <td className='AddExerciceView_emptyline'></td> </tr>
                <tr>
                    <td className='AddExerciceView_subtitle'>Support utilisé :</td>
                </tr>
                <tr>
                    <td className='AddExerciceView_info'>
                        <Select
                            name="support"
                            placeholder="Support"
                            closeMenuOnSelect={false}
                            defaultValue={add_supports}
                            options={SupportFilter}
                            onChange={o => { setAddSupports(o) }}
                            isMulti
                        />
                    </td>
                </tr>

                <tr> <td className='AddExerciceView_emptyline'></td> </tr>
                <tr> <td className='AddExerciceView_emptyline'></td> </tr>
                <tr> <td className='AddExerciceView_emptyline'></td> </tr>
                <tr>
                    <td className='AddExerciceView_subtitle'>Situation :</td>
                </tr>
                <tr>
                    <td className='AddExerciceView_info'>
                        <Editor
                            wrapperClassName="wrapper-class"
                            editorClassName="editor-class"
                            toolbarClassName="toolbar-class"
                            toolbar={{
                                options: ['inline', 'list', 'textAlign'],
                                inline: {
                                    options: ['bold', 'italic', 'underline'],
                                }
                            }}
                            editorState={situation}
                            onEditorStateChange={e => {
                                //updateExerciceDescription(i, draftToHtml(convertToRaw(e.getCurrentContent()))); 
                                setSituation(e);
                            }}
                            handlePastedText={() => false}
                            onPaste={e => { console.log(e) }}
                        />
                    </td>
                </tr>



                <tr>
                    <td> </td>
                </tr>

            </BasicInput>
        )
    }

    function EditView() {
        const [name, setName] = useState(exercice_to_edit.name);
        const [objectif, setObjectif] = useState(exercice_to_edit.objectif);
        const [hours, setHours] = useState(exercice_to_edit.hours);
        const [minutes, setMinutes] = useState(exercice_to_edit.minutes);

        const [add_ages, setAddAges] = useState(exercice_to_edit.ages);
        const [add_cycles, setAddCycles] = useState(exercice_to_edit.cycles);
        const [add_supports, setAddSupports] = useState(exercice_to_edit.supports);
        const [add_technics, setAddTechnics] = useState(exercice_to_edit.technics);
        const [add_physics, setAddPhysics] = useState(exercice_to_edit.physics);
        const [add_tactics, setAddTactics] = useState(exercice_to_edit.tactics);
        const [add_mentals, setAddMentals] = useState(exercice_to_edit.mentals);
        const [situation, setSituation] = useState(exercice_to_edit.situation);


        function save() {
            let a = [];
            add_ages.forEach((e) => {
                a.push(e.value);
            })

            let c = [];
            add_cycles.forEach((e) => {
                c.push(e.value);
            })

            let s = [];
            add_supports.forEach((e) => {
                s.push(e.value);
            })

            let te = [];
            add_technics.forEach((e) => {
                te.push(e.value);
            })

            let p = [];
            add_physics.forEach((e) => {
                p.push(e.value);
            })

            let ta = [];
            add_tactics.forEach((e) => {
                ta.push(e.value);
            })

            let me = [];
            add_mentals.forEach((e) => {
                me.push(e.value);
            })

            let e = {
                name: name,
                time: (hours * 60) + minutes,
                objectif: objectif,
                ages: a,
                cycles: c,
                supports: s,
                technics: te,
                physics: p,
                tactics: ta,
                mentals: me,
                situation: draftToHtml(convertToRaw(situation.getCurrentContent()))
            }

            db.collection("Users").doc(auth.currentUser.uid).collection("Exercices").doc(exercice_to_edit.id).update(e)

            toExercices();
        }

        return (
            <BasicInput
                hide={() => toExercice(exercice)}
                title={name}
                setTitle={setName}
                buttons={
                    <button onClick={() => { save() }}><span className="material-symbols-outlined">save</span></button>
                }
            >
                <tr>
                    <td className='AddExerciceView_subtitle'>Durée : </td>
                </tr>
                <tr>
                    <td className='AddExerciceView_info'>
                        <select value={hours} onChange={(e) => setHours(parseInt(e.target.value))}>
                            <option value={0}>0h</option>
                            <option value={1}>1h</option>
                            <option value={2}>2h</option>
                            <option value={3}>3h</option>
                            <option value={4}>4h</option>
                            <option value={5}>5h</option>
                            <option value={6}>6h</option>
                            <option value={7}>7h</option>
                            <option value={8}>8h</option>
                            <option value={9}>9h</option>
                            <option value={10}>10h</option>
                            <option value={11}>11h</option>
                            <option value={12}>12h</option>
                        </select>
                        <select value={minutes} onChange={(e) => setMinutes(parseInt(e.target.value))}>
                            <option value={0}>0min</option>
                            <option value={5}>5min</option>
                            <option value={10}>10min</option>
                            <option value={15}>15min</option>
                            <option value={20}>20min</option>
                            <option value={25}>25min</option>
                            <option value={30}>30min</option>
                            <option value={35}>35min</option>
                            <option value={40}>40min</option>
                            <option value={45}>45min</option>
                            <option value={50}>50min</option>
                            <option value={55}>55min</option>
                        </select>
                    </td>
                </tr>

                <tr><td className='AddExerciceView_emptyline'></td></tr>
                <tr>
                    <td className='AddExerciceView_subtitle'>Objectif : </td>
                </tr>
                <tr>
                    <td className='AddExerciceView_info'>
                        <textarea className='AddExerciceView_objectif' title='Objectif' onChange={(e) => setObjectif(e.target.value)} value={objectif} />
                    </td>
                </tr>
                <tr> <td className='AddExerciceView_emptyline'></td> </tr>
                <tr>
                    <td className='AddExerciceView_subtitle'>Catégorie d'age :</td>
                </tr>
                <tr>
                    <td className='AddExerciceView_info'>
                        <Select
                            name="ages"
                            placeholder="Ages"
                            closeMenuOnSelect={false}
                            defaultValue={add_ages}
                            options={AgeFilter}
                            onChange={o => { setAddAges(o) }}
                            isMulti
                        />
                    </td>
                </tr>

                <tr> <td className='AddExerciceView_emptyline'></td> </tr>
                <tr>
                    <td className='AddExerciceView_subtitle'>Ressource(s) travaillée(s) :</td>
                </tr>
                <tr>
                    <td className='AddExerciceView_info'>
                        - Physiques :
                        <Select
                            name="physiques"
                            placeholder="Physiques"
                            closeMenuOnSelect={false}
                            defaultValue={add_physics}
                            options={PhysicFilter}
                            onChange={o => { setAddPhysics(o) }}
                            isMulti
                        />
                    </td>
                </tr>
                <tr>
                    <td className='AddExerciceView_info'>
                        - Techniques :
                        <Select
                            name="techniques"
                            placeholder="Techniques"
                            closeMenuOnSelect={false}
                            defaultValue={add_technics}
                            options={TechnicFilter}
                            onChange={o => { setAddTechnics(o) }}
                            isMulti
                        />
                    </td>
                </tr>
                <tr>
                    <td className='AddExerciceView_info'>
                        - Tactiques :
                        <Select
                            name="tactiques"
                            placeholder="Tactiques"
                            closeMenuOnSelect={false}
                            defaultValue={add_tactics}
                            options={TacticFilter}
                            onChange={o => { setAddTactics(o) }}
                            isMulti
                        />
                    </td>
                </tr>
                <tr>
                    <td className='AddExerciceView_info'>
                        - Mental :
                        <Select
                            name="mentals"
                            placeholder="Mentals"
                            closeMenuOnSelect={false}
                            defaultValue={add_mentals}
                            options={MentalFilter}
                            onChange={o => { setAddMentals(o) }}
                            isMulti
                        />
                    </td>
                </tr>

                <tr> <td className='AddExerciceView_emptyline'></td> </tr>
                <tr>
                    <td className='AddExerciceView_subtitle'>Cycle de préparation :</td>
                </tr>
                <tr>
                    <td className='AddExerciceView_info'>
                        <Select
                            name="cycles"
                            placeholder="Cycles"
                            closeMenuOnSelect={false}
                            defaultValue={add_cycles}
                            options={CycleFilter}
                            onChange={o => { setAddCycles(o) }}
                            isMulti
                        />
                    </td>
                </tr>

                <tr> <td className='AddExerciceView_emptyline'></td> </tr>
                <tr>
                    <td className='AddExerciceView_subtitle'>Support utilisé :</td>
                </tr>
                <tr>
                    <td className='AddExerciceView_info'>
                        <Select
                            name="support"
                            placeholder="Support"
                            closeMenuOnSelect={false}
                            defaultValue={add_supports}
                            options={SupportFilter}
                            onChange={o => { setAddSupports(o) }}
                            isMulti
                        />
                    </td>
                </tr>

                <tr> <td className='AddExerciceView_emptyline'></td> </tr>
                <tr> <td className='AddExerciceView_emptyline'></td> </tr>
                <tr> <td className='AddExerciceView_emptyline'></td> </tr>
                <tr>
                    <td className='AddExerciceView_subtitle'>Situation :</td>
                </tr>
                <tr>
                    <td className='AddExerciceView_info'>
                        <Editor
                            wrapperClassName="wrapper-class"
                            editorClassName="editor-class"
                            toolbarClassName="toolbar-class"
                            toolbar={{
                                options: ['inline', 'list', 'textAlign'],
                                inline: {
                                    options: ['bold', 'italic', 'underline'],
                                }
                            }}
                            editorState={situation}
                            onEditorStateChange={e => {
                                //updateExerciceDescription(i, draftToHtml(convertToRaw(e.getCurrentContent()))); 
                                setSituation(e);
                            }}
                            handlePastedText={() => false}
                            onPaste={e => { console.log(e) }}
                        />
                    </td>
                </tr>



                <tr>
                    <td> </td>
                </tr>

            </BasicInput>
        )
    }

    function ExerciceView({ exo }) {
        const iterateCat = (list, list2) => {
            return list.map((e) => (
                list2.includes(e.value) ?
                    <span key={e.value} className='ExerciceView_cat'>{e.label},</span>
                    :
                    <></>
            ))
        }

        function deleteExercice(){
            let userId = auth.currentUser.uid;
            if (window.confirm('Voulez vous vraiment supprimer cet exercice? (cette action est irréversible)')) {
                db.collection("Users").doc(userId).collection("Exercices").doc(exercice.id).delete().then(() => {
                    console.log("Document successfully deleted!");
                }).catch((error) => {
                    console.error("Error removing document: ", error);
                });
                toExercices()
            }
        }

        return (
            <Basic title={exo.name} buttons={<>
                <button title="Supprimer l'exercice" onClick={() => {deleteExercice()}} ><span className="material-symbols-outlined">delete</span></button>
                <button title="Editer l'exercice" onClick={() => {toEditExercice(exo)}} ><span className="material-symbols-outlined">edit</span></button>
            </>} hide={toExercices}>
                <tr>
                    <td className='ExerciceView_subtitle'>
                        <b>Durée :</b> {timeToString(exo.time)}
                    </td>
                </tr>
                <tr>
                    <td className='ExerciceView_subtitle'>
                        <b>Objectif :</b>
                    </td>
                </tr>
                <tr>
                    <td className='ExerciceView_objectif'>
                        {exo.objectif}
                    </td>
                </tr>
                <tr>
                    <td className='ExerciceView_subtitle'>
                        <b>Catégorie d'age :</b>
                    </td>
                </tr>
                <tr>
                    <td className='ExerciceView_objectif'>
                        {exo.ages.length <= 0 ?
                            <span>Aucune</span>
                            :
                            iterateCat(AgeFilter, exo.ages)
                        }
                    </td>
                </tr>
                <tr>
                    <td className='ExerciceView_subtitle'>
                        <b>Ressource(s) travaillée(s) :</b>
                    </td>
                </tr>
                <tr>
                    <td className='ExerciceView_objectif'>
                        - Physiques :&nbsp; 
                        {exo.physics.length <= 0 ?
                            <span>Aucune</span>
                            :
                            iterateCat(PhysicFilter, exo.physics)
                        }
                    </td>
                </tr>
                <tr>
                    <td className='ExerciceView_objectif'>
                        - Techinques :&nbsp; 
                        {exo.technics.length <= 0 ?
                            <span>Aucune</span>
                            :
                            iterateCat(TechnicFilter, exo.technics)
                        }
                    </td>
                </tr>
                <tr>
                    <td className='ExerciceView_objectif'>
                        - Tactiques :&nbsp; 
                        {exo.tactics.length <= 0 ?
                            <span>Aucune</span>
                            :
                            iterateCat(TacticFilter, exo.tactics)
                        }
                    </td>
                </tr>
                <tr>
                    <td className='ExerciceView_objectif'>
                        - Mentales :&nbsp; 
                        {exo.mentals.length <= 0 ?
                            <span>Aucune</span>
                            :
                            iterateCat(MentalFilter, exo.mentals)
                        }
                    </td>
                </tr>
                <tr>
                    <td className='ExerciceView_subtitle'>
                        <b>Cycle de préparation :</b>
                    </td>
                </tr>
                <tr>
                    <td className='ExerciceView_objectif'>
                        {exo.cycles.length <= 0 ?
                            <span>Aucune</span>
                            :
                            iterateCat(CycleFilter, exo.cycles)
                        }
                    </td>
                </tr>
                <tr>
                    <td className='ExerciceView_subtitle'>
                        <b>Support utilisé :</b>
                    </td>
                </tr>
                <tr>
                    <td className='ExerciceView_objectif'>
                        {exo.supports.length <= 0 ?
                            <span>Aucune</span>
                            :
                            iterateCat(SupportFilter, exo.supports)
                        }
                    </td>
                </tr>

                <tr>
                    <td className='ExerciceView_empty_line'>
                    </td>
                </tr>

                <tr>
                    <td className='ExerciceView_subtitle'>
                        <b>Situation :</b>
                    </td>
                </tr>
                <tr>
                    <td className='ExerciceView_objectif'>
                        <div className='ExerciceView_situation'  dangerouslySetInnerHTML={{ __html: exo.situation }}></div>
                    </td>
                </tr>
                
                <tr><td className='ExerciceRow_void'></td></tr>
            </Basic>
        )
    }

    const iterateExercice = (list) => {
        return list.map((e) => (
            <tr key={e.id}><td className='ExerciceRow_main'>
                <table className='ExerciceRow_table' onClick={() => { toExercice(e) }}>
                    <tbody>
                        <tr>
                            <td className='ExerciceRow_title'>{e.name}</td>
                            <td rowSpan={3} className='ExerciceRow_arrow'><span className="material-symbols-outlined">chevron_right</span></td>
                        </tr>
                        <tr>
                            <td className='ExerciceRow_objectif'>{e.objectif}</td>
                        </tr>
                    </tbody>
                </table>
            </td></tr>
        ))
    }


    if (state === Menu.Filters) {
        return (
            <FiltersView />
        )
    }

    if (state === Menu.AddExercice) {
        return (
            <AddView />
        )
    }

    if (state === Menu.EditExercice) {
        return (
            <EditView/>
        )
    }

    if (state === Menu.Exercice) {
        return (
            <ExerciceView exo={exercice} />
        )
    }

    return (
        <BasicAdd title="Exercices" hide={hide} add={toAddExercice} buttons={
            <button title="Filtrer les exercices" onClick={toFilters} ><span className="material-symbols-outlined">filter_list</span></button>
        } >
            {
                filtered_exercices.length <= 0 ?
                    <tr><td> Pas d'exercices</td></tr>
                    :
                    iterateExercice(filtered_exercices)
            }
            <tr><td className='ExerciceRow_void'></td></tr>

        </BasicAdd>
    )
}