import './PlanifView.css'
import { TraineeContext, DateContext } from './Context.js';
import DateObject from "react-date-object";
//import { useMediaQuery } from 'react-responsive';
import { useState } from 'react';
import { ModalView } from './ModalView.js';
import { SmallCalendar } from './SmallCalendar.js';
import { Colonne } from './Colonne.js';

export function PlanifView({ toInfoCycle, toInfoTrainee, toSession, toAddSession }) {
    const [small_calendar, setSmallCalendar] = useState(false);


    //const isMedium = useMediaQuery({ query: `(min-width: 1000px)` });
    //const isBig = useMediaQuery({ query: `(min-width: 1400px)` });


    function showSmallCalendar() {
        setSmallCalendar(true);
    }

    function hideSmallCalendar() {
        setSmallCalendar(false);
    }


    function ViewSizer() {
        /*if (isBig) {
            return (
                <DateContext.Consumer>
                    {(d) => (
                        <TraineeContext.Consumer>
                            {(t) => (
                                <table className='PlanifViewDual_table'><tbody>
                                    <tr>
                                        <td>
                                            <Calendar date={d.date} />
                                        </td>
                                        <td className='PlanifViewDual_cycle'>
                                            <InfoCycle cycle={t.cycle} subcycle={t.subcycle} showEdition={toCyclesEditions} />
                                        </td>
                                    </tr>
                                </tbody></table>
                            )}
                        </TraineeContext.Consumer>
                    )}
                </DateContext.Consumer>
            )
        }

        if (isMedium) {
            return (
                <DateContext.Consumer>
                    {(d) => (
                        <TraineeContext.Consumer>
                            {(t) => (
                                <>
                                    <Calendar date={d.date} />
                                </>
                            )}
                        </TraineeContext.Consumer>
                    )}
                </DateContext.Consumer>
            )
        }*/

        return (
            <DateContext.Consumer>
                {(d) => (
                    <TraineeContext.Consumer>
                        {(t) => (
                            <>
                                <Colonne date={d.date} sessions={t.sessions} trainee={t.trainee} subcycle={t.subcycle} showSession={toSession}/>
                            </>
                        )}
                    </TraineeContext.Consumer>
                )}
            </DateContext.Consumer>
        )
    }


    return (
        <table className='PlanifView_table'>
            <tbody>
                <tr>
                    <td className='PlanifView_table_menu'>
                        <DateContext.Consumer>
                            {(d) => (
                                <TraineeContext.Consumer>
                                    {(t) => (
                                        <PlanifViewMenu trainee={t} showInfoButton={true/*(!isBig)*/} showInfoCycle={toInfoCycle} showSmallCalendar={showSmallCalendar} showInfoUser={toInfoTrainee} showAddSession={toAddSession} date={d.date} setDate={d.setDate} />
                                    )}
                                </TraineeContext.Consumer>
                            )}
                        </DateContext.Consumer>
                    </td>
                </tr>
                <tr>
                    <td className='PlanifView_table_corps'>
                        <ViewSizer />
                        <DateContext.Consumer>
                            {(d) => (
                                <TraineeContext.Consumer>
                                    {(t) => (
                                        <>
                                            <ModalView isVisible={small_calendar} hide={hideSmallCalendar}>
                                                <SmallCalendar d={d.date} setD={d.setDate} />
                                            </ModalView>
                                            {// <EditionCycles isVisible={edition_cycles} hide={hideEditionCycles} trainee={t.trainee}/>
                                            }
                                        </>
                                    )}
                                </TraineeContext.Consumer>
                            )}
                        </DateContext.Consumer>
                    </td>
                </tr>
            </tbody>
        </table>
    )
}

function PlanifViewMenu({ trainee, date, setDate, showInfoCycle, showInfoButton, showInfoUser, showAddSession }) {
    const [calendar, setCalendar] = useState(false);

    function nextWeek() {
        setDate(new DateObject(date.add(7, "days")))
    }

    function previousWeek() {
        setDate(new DateObject(date.add(-7, "days")))
    }

    function toCurrentWeek() {
        setDate(new DateObject())
    }

    if (trainee.trainee === null) {
        return (
            <table className='PlanifViewMenu_table'>
                <tbody>
                    <tr>
                        <td>
                        </td>
                    </tr>
                </tbody>
            </table>
        )
    }

    return (
        <table className='PlanifViewMenu_table'>
            <tbody>
                <tr>
                    <td className='PlanifViewMenu_user'>
                        <button title={"Info personne/groupes"} onClick={() => showInfoUser()} ><span className="material-symbols-outlined">person</span></button>
                    </td>
                    <td className='PlanifViewMenu_seance'>
                        <button title="Ajouter une séance" onClick={() => showAddSession()} ><span className="material-symbols-outlined">add</span></button>
                    </td>
                    <td className='PlanifViewMenu_calendar'>
                        <span onMouseEnter={() => setCalendar(true)} onMouseLeave={() => setCalendar(false)} ><button><span className="material-symbols-outlined">calendar_month</span></button>
                            {
                                calendar ?
                                    (<div className='SmallCalendarModal'><SmallCalendar d={date} setD={setDate} /></div>)
                                    :
                                    (<></>)
                            }
                        </span>
                        <button title="Semaine précédente" onClick={() => previousWeek()}><span className="material-symbols-outlined">arrow_back</span></button>
                        <button title="Aujourd'hui" onClick={() => toCurrentWeek()}><span className="material-symbols-outlined">today</span></button>
                        <button title="Semaine suivante" onClick={() => nextWeek()}><span className="material-symbols-outlined">arrow_forward</span></button>
                    </td>
                    {
                        showInfoButton ?
                            (<td className='PlanifViewMenu_cycle'>
                                <button title="Info cycle" onClick={() => showInfoCycle()}><span className="material-symbols-outlined">info</span></button>
                            </td>)
                            :
                            (<td className='PlanifViewMenu_cycle_empty'>
                            </td>)
                    }
                </tr>
            </tbody>
        </table>
    )
}