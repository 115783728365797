export function timeToString(t) {
    let s = "";
    let m = t;
    let h = Math.floor(m / 60.0);
    m -= h * 60;

    if (h > 0) {
        s += h + "h";
        if (m > 0) {
            if(m < 10){
                s += "0";
            }
            s += m;
        }
    } else {
        s += m + "min";
    }

    return s;
}