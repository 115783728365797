import { useState } from 'react'
import './AddSession.css'
import { SmallCalendar } from './SmallCalendar.js';
import DateObject from 'react-date-object';
import Select from 'react-select';
import { uid } from 'uid';
import { db } from "./firebase.js";
import { Editor } from 'react-draft-wysiwyg';
import '../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import htmlToDraft from 'html-to-draftjs';
import draftToHtml from 'draftjs-to-html';
import { convertToRaw, EditorState, ContentState } from 'draft-js';
import { BasicInput } from './Basic.js';
import { ExerciceChooserView } from './ExerciceChooser.js';


export function AddSession({ date_session, hide, subcycle_session, trainee, exercices_library }) {
    const [date, setDate] = useState(date_session);
    const [name, setName] = useState("Nouvelle Séance");
    const [objectifs, setObjectifs] = useState([]);
    const [calendar, setCalendar] = useState(false);
    const [exercices, setExercices] = useState([]);

    const Menu = {
        Session: 'Session',
        ExerciceChooser: 'ExerciceChooser'
    };

    const [state, setState] = useState(Menu.Session);
    const [indice, setIndice] = useState(-1);

    function lookForExercice(a) {
        setIndice(a);
        setState(Menu.ExerciceChooser)
    }

    function addExecicFromLibrary(n, t, s) {
        const blocksFromHtml = htmlToDraft(s);
        const { contentBlocks, entityMap } = blocksFromHtml;
        const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
        const editorState = EditorState.createWithContent(contentState);
        let ex = {
            id: uid(),
            name: n,
            time: t,
            description: editorState,
            files: [],
        };
        if (indice < 0) {
            let es = [...exercices];
            es.push(ex);
            setExercices(es);
        }else{
            let es = [
                ...exercices.slice(0, indice),
                ex,
                ...exercices.slice(indice)
            ];
            setExercices(es);
        }
        setState(Menu.Session);
    }

    function updateDay(d) {
        let a = new DateObject(date);
        a.setDay(d.day);
        a.setMonth(d.month);
        a.setYear(d.year);
        setDate(a);
    }

    function updateHour(h) {
        let a = new DateObject(date);
        a.setHour(h);
        setDate(a);
    }

    function updateMinute(m) {
        let a = new DateObject(date);
        a.setMinute(m);
        setDate(a);
    }

    function updateObjectifs(o) {
        setObjectifs(o);
    }


    function updateExerciceName(i, n) {
        let p = [...exercices];
        p[i].name = n;
        setExercices(p);
    }

    function updateExerciceTime(i, n) {
        let p = [...exercices];
        p[i].time = n;
        setExercices(p);
    }

    function updateExerciceDescription(i, n) {
        let p = [...exercices];
        p[i].description = n;
        setExercices(p);
    }

    function getTime() {
        let t = 0;
        exercices.forEach((e) => {
            t += e.time;
        })
        return t;
    }

    function addExecice() {
        let es = [...exercices];
        es.push({
            id: uid(),
            name: "Nouvel exercice",
            time: 0,
            description: EditorState.createEmpty(),
            files: [],
        });
        setExercices(es);
    }

    function addExeciceAt(index) {
        let n = {
            id: uid(),
            name: "Nouvel exercice",
            time: 0,
            description: EditorState.createEmpty(),
            files: [],
        };
        let es = [
            ...exercices.slice(0, index),
            n,
            ...exercices.slice(index)
        ];
        setExercices(es);
    }

    function removeExeciceAt(index) {
        let es = [...exercices];
        es.splice(index, 1);
        setExercices(es);
    }


    function moveUpExeciceAt(index) {
        let es = [...exercices];
        let e = es[index];
        es.splice(index, 1);
        es.splice(index - 1, 0, e);

        /*let esr = [
            ...es.slice(0, index-1),
            e, 
            ...es.slice(index-1)
        ];*/

        setExercices(es);
    }

    function moveDownExeciceAt(index) {
        let es = [...exercices];
        let e = es[index];
        es.splice(index, 1);
        es.splice(index + 1, 0, e);
        setExercices(es);
    }

    function timeToString(t) {
        let s = "";
        let m = t;
        let h = Math.floor(m / 60.0);
        m -= h * 60;

        if (h > 0) {
            s += h + "h";
            if (m > 0) {
                if(m < 10){
                    s += "0";
                }
                s += m;
            }
        } else {
            s += m + "min";
        }

        return s;
    }

    function save() {
        let exos = [];
        exercices.forEach((e) => {
            exos.push({
                id: e.id,
                name: e.name,
                time: e.time,
                description: draftToHtml(convertToRaw(e.description.getCurrentContent())),
                files: [],
            });
        })

        let s = {
            date: date.format("YYYY/MM/DD HH:mm:ss"),
            objectifs: objectifs,
            name: name,
            time: getTime(),
            exercices: exos
        }

        //console.log(s);
        db.collection("Trainees").doc(trainee.id).collection("Seances").add(s);

        hide();
    }

    function Times({ i }) {
        return (
            <select value={exercices[i].time} onChange={(e) => updateExerciceTime(i, parseInt(e.target.value))}>
                <option value={0}>0min</option>
                <option value={5}>5min</option>
                <option value={10}>10min</option>
                <option value={15}>15min</option>
                <option value={20}>20min</option>
                <option value={25}>25min</option>
                <option value={30}>30min</option>
                <option value={35}>35min</option>
                <option value={40}>40min</option>
                <option value={45}>45min</option>
                <option value={50}>50min</option>
                <option value={55}>55min</option>
                <option value={60}>1h</option>
                <option value={75}>1h15</option>
                <option value={90}>1h30</option>
                <option value={105}>1h45</option>
                <option value={120}>2h</option>
                <option value={150}>2h30</option>
                <option value={180}>3h</option>
                <option value={210}>3h30</option>
                <option value={240}>4h</option>
            </select>
        )
    }

    function iterateExercices() {
        let exos = [];
        for (let i = 0; i < exercices.length; i++) {
            exos.push(
                <table key={exercices[i].id} className='AddSession_exercice'>
                    <tbody>
                        <tr>
                            <td colSpan={2} className='AddSession_exercice_add'>
                                <button onClick={() => addExeciceAt(i)}><span className="material-symbols-outlined">add</span></button>
                                <button onClick={() => lookForExercice(i)}><span className="material-symbols-outlined">library_add</span></button>
                            </td>
                        </tr>
                        <tr>
                            <td><input type='text' className='AddSession_exercice_title' value={exercices[i].name} onChange={e => updateExerciceName(i, e.target.value)} /></td>

                            <td rowSpan={3} className='AddSession_exercice_right'>
                                <button disabled={i === 0} onClick={() => moveUpExeciceAt(i)}><span className="material-symbols-outlined">arrow_upward</span></button>
                                <button onClick={() => removeExeciceAt(i)}><span className="material-symbols-outlined">remove</span></button>
                                <button disabled={i === (exercices.length - 1)} onClick={() => moveDownExeciceAt(i)}><span className="material-symbols-outlined">arrow_downward</span></button>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                Durée : <Times i={i} />
                            </td>
                        </tr>
                        <tr>
                            <td className='AddSession_text_editor'>
                                <Editor
                                    toolbarClassName="toolbarClassName"
                                    toolbarStyle={{ border: "1px solid gray", backgroundColor: "var(--main-bg-color)" }}
                                    wrapperClassName="wrapperClassName"
                                    editorClassName="editor-class"
                                    toolbar={{
                                        options: ['inline', 'list', 'textAlign'],
                                        inline: {
                                            options: ['bold', 'italic', 'underline'],
                                        }
                                    }}
                                    editorState={exercices[i].description}
                                    onEditorStateChange={e => {
                                        //updateExerciceDescription(i, draftToHtml(convertToRaw(e.getCurrentContent()))); 
                                        updateExerciceDescription(i, e)
                                    }}
                                    handlePastedText={() => false}
                                    onPaste={e => {console.log(e)}}
                                />
                            </td>
                        </tr>
                    </tbody>
                </table>
            );

            //console.log(exercices[i].id+"-exo")
        }
        return (
            <>{exos}</>
        )
    }

    let list_objectifs = [];

    if (subcycle_session !== null) {
        subcycle_session.physics.forEach(element => {
            list_objectifs.push({
                value: element.id,
                label: element.name
            })
        });
        subcycle_session.technics.forEach(element => {
            list_objectifs.push({
                value: element.id,
                label: element.name
            })
        });
        subcycle_session.climbings.forEach(element => {
            list_objectifs.push({
                value: element.id,
                label: element.name
            })
        });
        subcycle_session.others.forEach(element => {
            list_objectifs.push({
                value: element.id,
                label: element.name
            })
        });
    }

    if(state === Menu.ExerciceChooser){
        return (
            <ExerciceChooserView hide={() => {setState(Menu.Session)}} exercices={exercices_library} add={addExecicFromLibrary}/>
        )
    }

    return (
        <BasicInput title={name} setTitle={setName} hide={hide} buttons={
            <button onClick={() => { save() }}><span className="material-symbols-outlined">save</span></button>
        }>
            <>
                <tr><td className='AddSession_empty_row'>
                </td></tr>
                <tr><td className='AddSession_date'>
                    Le :
                    <span onClick={() => setCalendar(true)}  > {date.format("DD/MM/YYYY")}
                    </span>
                    {
                        calendar ?
                            (<div className='SmallCalendarModal' onMouseLeave={() => setCalendar(false)} ><SmallCalendar d={date} setD={updateDay} hide={() => { setCalendar(false); console.log(calendar) }} /></div>)
                            :
                            (<></>)
                    }
                </td></tr>
                <tr><td className='AddSession_date'>
                    A :
                    <select value={date.hour} onChange={(e) => updateHour(parseInt(e.target.value))}>
                        <option value={0}>0h</option>
                        <option value={1}>1h</option>
                        <option value={2}>2h</option>
                        <option value={3}>3h</option>
                        <option value={4}>4h</option>
                        <option value={5}>5h</option>
                        <option value={6}>6h</option>
                        <option value={7}>7h</option>
                        <option value={8}>8h</option>
                        <option value={9}>9h</option>
                        <option value={10}>10h</option>
                        <option value={11}>11h</option>
                        <option value={12}>12h</option>
                        <option value={13}>13h</option>
                        <option value={14}>14h</option>
                        <option value={15}>15h</option>
                        <option value={16}>16h</option>
                        <option value={17}>17h</option>
                        <option value={18}>18h</option>
                        <option value={19}>19h</option>
                        <option value={20}>20h</option>
                        <option value={21}>21h</option>
                        <option value={22}>22h</option>
                        <option value={23}>23h</option>
                    </select>

                    <select value={date.minute} onChange={(e) => updateMinute(parseInt(e.target.value))}>
                        <option value={0}>00</option>
                        <option value={15}>15</option>
                        <option value={30}>30</option>
                        <option value={45}>45</option>
                    </select>
                </td></tr>
                <tr><td className='AddSession_date'>
                    Durée : {timeToString(getTime())}
                </td></tr>
                {
                    subcycle_session !== null ?
                        subcycle_session.start <= date && subcycle_session.end >= date ?
                            (<tr><td className='AddSession_objectifs'>
                                Objectifs :
                                <Select
                                    name="objectifs"
                                    placeholder="Objectifs"
                                    closeMenuOnSelect={false}
                                    defaultValue={objectifs}
                                    options={list_objectifs}
                                    onChange={o => { updateObjectifs(o) }}
                                    isMulti
                                />
                            </td></tr>)
                            :
                            (<tr><td className='AddSession_objectifs_none'>
                                Vous ne pouvez pas selectionner vos objectifs.
                                <span infoBulle={"vous devez etre dans le sous-cycle de la fenetre principale"} ><span className="material-symbols-outlined">info</span></span>
                            </td></tr>)
                        :
                        (<tr><td className='AddSession_objectifs_none'>
                            Vous ne pouvez pas selectionner vos objectifs.
                            <span infoBulle={"vous devez etre dans le sous-cycle de la fenetre principale"} ><span className="material-symbols-outlined">info</span></span>
                        </td></tr>)

                }
                <tr><td className='AddSession_date'>
                    Exercices :
                </td></tr>
                <tr><td>
                    {iterateExercices()}
                </td></tr>
                <tr>
                    <td className='AddSession_exercice_add'>
                        <button onClick={() => addExecice()}><span className="material-symbols-outlined">add</span></button>
                        <button onClick={() => lookForExercice(-1)}><span className="material-symbols-outlined">library_add</span></button>
                    </td>
                </tr>
                <tr>
                    <td className='AddSession_fill_void'>
                    </td>
                </tr>
            </>
        </BasicInput>
        /*<div className='AddSession'>
            <table className='AddSession_table'>
                <tbody>
                    <tr><td className='AddSession_title'>
                        <input type='text' className='AddSession_title_input' value={name} onChange={(e) => setName(e.target.value)} />
                    </td></tr>
                    <tr><td className='AddSession_empty_row'>
                    </td></tr>
                    <tr><td className='AddSession_date'>
                        Le :
                        <span onClick={() => setCalendar(true)}  > {date.format("DD/MM/YYYY")}
                        </span>
                        {
                            calendar ?
                                (<div className='SmallCalendarModal' onMouseLeave={() => setCalendar(false)} ><SmallCalendar d={date} setD={updateDay} hide={() => { setCalendar(false); console.log(calendar) }} /></div>)
                                :
                                (<></>)
                        }
                    </td></tr>
                    <tr><td className='AddSession_date'>
                        A :
                        <select value={date.hour} onChange={(e) => updateHour(parseInt(e.target.value))}>
                            <option value={0}>0h</option>
                            <option value={1}>1h</option>
                            <option value={2}>2h</option>
                            <option value={3}>3h</option>
                            <option value={4}>4h</option>
                            <option value={5}>5h</option>
                            <option value={6}>6h</option>
                            <option value={7}>7h</option>
                            <option value={8}>8h</option>
                            <option value={9}>9h</option>
                            <option value={10}>10h</option>
                            <option value={11}>11h</option>
                            <option value={12}>12h</option>
                            <option value={13}>13h</option>
                            <option value={14}>14h</option>
                            <option value={15}>15h</option>
                            <option value={16}>16h</option>
                            <option value={17}>17h</option>
                            <option value={18}>18h</option>
                            <option value={19}>19h</option>
                            <option value={20}>20h</option>
                            <option value={21}>21h</option>
                            <option value={22}>22h</option>
                            <option value={23}>23h</option>
                        </select>

                        <select value={date.minute} onChange={(e) => updateMinute(parseInt(e.target.value))}>
                            <option value={0}>00</option>
                            <option value={15}>15</option>
                            <option value={30}>30</option>
                            <option value={45}>45</option>
                        </select>
                    </td></tr>
                    <tr><td className='AddSession_date'>
                        Durée : {timeToString(time)}
                    </td></tr>
                    { 
                        subcycle_session !== null ?
                            subcycle_session.start <= date &&  subcycle_session.end >= date?
                                (<tr><td className='AddSession_objectifs'>
                                    Objectifs :
                                    <Select
                                        name="objectifs"
                                        placeholder="Objectifs"
                                        closeMenuOnSelect={false}
                                        defaultValue={objectifs}
                                        options={list_objectifs}
                                        onChange={o => { updateObjectifs(o) }}
                                        isMulti
                                    />
                                </td></tr>)
                                :
                                (<tr><td className='AddSession_objectifs_none'>
                                    Vous ne pouvez pas selectionner vos objectifs.
                                    <span infoBulle={"vous devez etre dans le sous-cycle de la fenetre principale"} ><span className="material-symbols-outlined">info</span></span>
                                </td></tr>)
                        :
                            (<tr><td className='AddSession_objectifs_none'>
                                    Vous ne pouvez pas selectionner vos objectifs.
                                    <span infoBulle={"vous devez etre dans le sous-cycle de la fenetre principale"} ><span className="material-symbols-outlined">info</span></span>
                                </td></tr>)
                        
                    }
                    <tr><td className='AddSession_date'>
                        Exercices :
                    </td></tr>
                    <tr><td>
                        <div className='AddSession_exercices'>
                            {iterateExercices()}

                            <button onClick={() => addExecice()}><span className="material-symbols-outlined">add</span></button>
                        </div>
                    </td></tr>
                    <tr><td className='AddSession_button'>
                        <div className='AddSession_button_div'>
                            <button onClick={() => hide()}><span className="material-symbols-outlined">close</span></button>
                            <button onClick={() => { save() }}><span className="material-symbols-outlined">save</span></button>
                        </div>
                    </td></tr>
                </tbody>
            </table>
        </div>*/
    )
}