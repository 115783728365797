import './AddTrainee.css';
import { Basic } from './Basic.js';
import { db, auth } from "./firebase.js";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";

export function AddTrainee({ onClose }) {

    let name = "";

    function save() {
        if (name === "") {
            alert("Erreur : Pas de nom");
        } else {
            db.collection("Trainees").add(
                {
                    name: name
                }
            ).then(function (docRef) {
                let userId = auth.currentUser.uid;
                db.collection("Users").doc(userId).update({ "trainees": firebase.firestore.FieldValue.arrayUnion(docRef.id) })
            })
            onClose();
        }
    }

    return (
        <Basic title={"Ajouter une personne ou un groupe à entrainer"} hide={onClose} buttons={
            <button onClick={() => save()}><span className="material-symbols-outlined">add</span></button>
        }>
            <tr>
                <td className='AddTrainee_form_left'>Nom : </td>
                <td className='AddTrainee_form_right'><input type='text' name='newName' onChange={e => name = e.target.value} /></td>
            </tr>
        </Basic>
        /*
        <div className="AddTrainee">
            <table className='AddTrainee_table'>
                <tbody>
                    <tr><td colSpan={2} className='AddTrainee_title'>
                        Ajouter une personne ou un groupe à entrainer
                    </td></tr>
                    <tr>
                        <td className='AddTrainee_form_left'>Nom : </td>
                        <td className='AddTrainee_form_right'><input type='text' name='newName' onChange={e => name = e.target.value} /></td>
                    </tr>
                    <tr>
                        <td colSpan={2} className='AddTrainee_button'>
                            <div className='AddTrainee_button_div'>
                                <button onClick={() => onClose()}><span className="material-symbols-outlined">close</span></button>
                                <button onClick={() => save()}><span className="material-symbols-outlined">add</span></button>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        */
    )
}