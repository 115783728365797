export const AgeFilter = [
    {value: 0, label: "4-7ans"},
    {value: 1, label: "8-9ans"},
    {value: 2, label: "10-11ans"},
    {value: 3, label: "12-13ans"},
    {value: 4, label: "14-15ans"},
    {value: 5, label: "16ans et plus"},
]

export const CycleFilter = [
    {value: 0, label: "Général"},
    {value: 1, label: "Orienté"},
    {value: 2, label: "Spécifique"},
]

export const SupportFilter = [
    {value: 0, label: "Blocs artificiels"},
    {value: 1, label: "Voies artificielles"},
    {value: 2, label: "Poutre/Gullich/Autres agrés"},
    {value: 3, label: "Sol/Tapis"},
    {value: 4, label: "Blocs naturels"},
    {value: 5, label: "Voies naturelles"},
    {value: 6, label: "Parc/Chemin/Route"},
]

export const TechnicFilter = [
    {value: 0, label: "Pieds"},
    {value: 1, label: "Mains"},
    {value: 2, label: "Equilibre"},
    {value: 3, label: "Placements"},
    {value: 4, label: "Coordinations"},
    {value: 5, label: "Relachements"},
    {value: 6, label: "Clippages"},
]

export const PhysicFilter = [
    {value: 0, label: "Général"},
    {value: 1, label: "Cardio"},
    {value: 2, label: "Doigts"},
    {value: 3, label: "Bras"},
    {value: 4, label: "Force"},
    {value: 5, label: "Résistance"},
    {value: 6, label: "Endurance"},
    {value: 7, label: "Volume"},
    {value: 8, label: "Vitesse"},
    {value: 9, label: "Gainage"},
    {value: 10, label: "Abdos"},
    {value: 11, label: "Echauffements"},
]

export const TacticFilter = [
    {value: 0, label: "Lecture"},
    {value: 1, label: "Mémorisation"},
    {value: 2, label: "Réorganisation"},
    {value: 3, label: "Rythme"},
    {value: 4, label: "Gestion de l'effort"}
]

export const MentalFilter = [
    {value: 0, label: "Combativité"},
    {value: 1, label: "Persévérance"},
    {value: 2, label: "Engagement"},
]