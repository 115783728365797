// Import the functions you need from the SDKs you need
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import  "firebase/compat/auth";
//import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAVnnT3hs7WHXdhLyrSS-6BUKKsXayRhx0",
  authDomain: "planif-80b65.firebaseapp.com",
  projectId: "planif-80b65",
  storageBucket: "planif-80b65.appspot.com",
  messagingSenderId: "67213262135",
  appId: "1:67213262135:web:98ac03d43540140698eeb2",
  measurementId: "G-MNQ74VL43R"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);
const db = firebase.firestore();
const auth = firebase.auth();
//const analytics = getAnalytics(app);

export { db, auth }