import { useState } from 'react';
import './EditSubcycle.css';
import DateObject from 'react-date-object';
import { db } from "../firebase.js";
import { BasicInput } from '../Basic.js';


export function AddSubcycle({ trainee, cycle, start, numberOfWeek, hide }) {
    const [name, setName] = useState("Sous cycle");
    const [debut, setDebut] = useState(0);
    const [fin, setFin] = useState(numberOfWeek - 1);

    let weeks = [];
    let d = new DateObject(start);
    for (let i = 0; i < numberOfWeek; i++) {
        let s = new DateObject(d);
        let e = (new DateObject(d)).add(6, "days");
        weeks.push({
            start: s,
            end: e,
            week: s.weekOfYear,
        })

        d.add(7, "days");
    }

    function saveAndExit() {
        let n = fin - debut + 1;

        let res = {
            name: name,
            start: weeks[debut].start.format(),
            end: weeks[fin].end.format(),
            weeks: n,
            loads: Array(n).fill(0),
            physics: [],
            technics: [],
            climbings: [],
            others: [],
        }

        db.collection("Trainees").doc(trainee.id).collection("Cycles").doc(cycle.id).collection("SubCycles").add(res).then((docRef) => {
            console.log("Document written");
        })
            .catch((error) => {
                console.error("Error adding document: ", error);
            });

        hide();
    }

    function StartChooser() {
        let options = [];
        for (let i = 0; i <= fin && i < weeks.length; i++) {
            options.push(<option key={i} value={i}>Semaine {weeks[i].week} : Du {weeks[i].start.format("DD/MM/YYYY")} au {weeks[i].end.format("DD/MM/YYYY")} </option>)
        }
        return (
            <select value={debut} onChange={e => setDebut(parseInt(e.target.value))}>
                {options}
            </select>
        )
    }

    function EndChooser() {
        let options = [];
        for (let i = debut; i < weeks.length; i++) {
            options.push(<option key={i} value={i}>Semaine {weeks[i].week} : Du {weeks[i].start.format("DD/MM/YYYY")} au {weeks[i].end.format("DD/MM/YYYY")} </option>)
        }
        return (
            <select value={fin} onChange={e => setFin(parseInt(e.target.value))}>
                {options}
            </select>
        )
    }

    return (
        <BasicInput title={name} setTitle={setName} hide={hide} buttons={
            <button onClick={() => saveAndExit()}><span className="material-symbols-outlined">save</span></button>
        }>
            <>
                <tr><td className='EditSubcycle_add_title'>Première semaine du cycle :</td></tr>
                <tr><td className='EditSubcycle_add_chooser'><StartChooser /></td></tr>
                <tr><td className='EditSubcycle_add_title'>Dernière semaine du cycle :</td></tr>
                <tr><td className='EditSubcycle_add_chooser'><EndChooser /></td></tr>
                <tr><td></td></tr>
            </>
        </BasicInput>
        /*
        <div className='EditSubcycle'>
            <div className='EditSubcycle_body'>
                <table className='EditSubcycle_table'>
                    <tbody>
                        <tr><td className='EditSubcycle_table_title'>
                            <input className='EditSubcycle_table_title_input' type='text' value={name} onChange={e => setName(e.target.value)} />
                        </td></tr>
                        <tr><td className='EditSubcycle_add_title'>Première semaine du cycle :</td></tr>
                        <tr><td className='EditSubcycle_add_chooser'><StartChooser/></td></tr>
                        <tr><td className='EditSubcycle_add_title'>Dernière semaine du cycle :</td></tr>
                        <tr><td className='EditSubcycle_add_chooser'><EndChooser/></td></tr>
                        <tr><td></td></tr>
                        <tr><td className='EditSubcycle_table_button'>
                        <div className='EditSubcycle_table_button_div'>
                            <button onClick={() => hide()}><span className="material-symbols-outlined">close</span></button>
                            <button onClick={() => saveAndExit()}><span className="material-symbols-outlined">save</span></button>
                        </div>
                        </td></tr>
                    </tbody>
                </table>
            </div>
        </div>*/
    )
}