import { createContext } from 'react';
import DateObject from 'react-date-object';

export const DateContext = createContext({
    date: new DateObject(),
    setDate: (d) => {}
});

export const TraineeContext = createContext({
    trainee: null,
    cycle: null,
    subcycle: null
});
