import './SmallCalendar.css';
import DateObject from "react-date-object";
import { useState } from 'react';

export function SmallCalendar({d, setD, hide}){
    const nameMonth = ["Janvier","Février","Mars","Avril","Mai","Juin","Juillet","Août","Septembre","Octobre","Novembre","Décembre"];
    const [date, setDate] = useState(new DateObject(d));


    
    function previousMonth(){
        setDate(new DateObject({
            year: date.year,
            month: date.month.number - 1,
            day: 1  
        }))
    }

    function nextMonth(){
        setDate(new DateObject({
            year: date.year,
            month: date.month.number+1,
            day: 1  
        }))
    }

    function previousYear(){
        setDate(new DateObject({
            year: date.year - 1,
            month: date.month.number,
            day: 1  
        }))
    }

    function nextYear(){
        setDate(new DateObject({
            year: date.year + 1,
            month: date.month.number,
            day: 1  
        }))
    }

    let monthContent = nameMonth[date.monthIndex]
    let yearContent = date.year

    let number_days = date.toLastOfMonth().day
    let day_in_week = date.toFirstOfMonth().weekDay.index

    if(day_in_week === 0)
        day_in_week = 7;
    let day_one = day_in_week;
    day_in_week--;

    let nb_week = Math.ceil((number_days + day_in_week)/7);
    let days_to_print = nb_week * 7;
    
    let resultC = [];
    let contentCalendar;

    //const a = [L, M, M, J, V, S, D];

    for(let i = 1; i <= days_to_print; i += 7) {
        let temp = []

        for(let j = 0; j < 7; j++){
            if( ((i + j) < (number_days + day_one)) && ( (i + j) >= day_one)){
                //temp.push(i + j - day_one + 1)
                temp.push(<button onClick={() => {
                    setD(
                        new DateObject({
                            year: date.year,
                            month: date.month.number,
                            day: (i + j - day_one + 1)
                        })
                    );
                    hide();
                }}>{i + j - day_one + 1}</button>)
            }else{
                temp.push("")
            }
        }
        resultC.push(temp)
    }

    const iterateItem = (items) => {
        return items.map((item) => (
            <tr key={(Math.random() + 1).toString(36).substring(7)}>
                <td className="SmallCalendar-cell-body">{item[0]}</td>
                <td className="SmallCalendar-cell-body">{item[1]}</td>
                <td className="SmallCalendar-cell-body">{item[2]}</td>
                <td className="SmallCalendar-cell-body">{item[3]}</td>
                <td className="SmallCalendar-cell-body">{item[4]}</td>
                <td className="SmallCalendar-cell-body">{item[5]}</td>
                <td className="SmallCalendar-cell-body">{item[6]}</td>
             </tr>
        ))
    }

    contentCalendar = iterateItem(resultC)


    return (
        <div className='SmallCalendar'>
            <div className='SmallCalendar-headline'>
                <button className='ButtonSmallCalendar' onClick={previousMonth}> {"<<"} </button>
                
                <span className='SmallCalendar-month'>
                    {monthContent}
                </span>
                
                <button className='ButtonSmallCalendar' onClick={nextMonth}> {">>"} </button>

                <button className='ButtonSmallCalendar' onClick={previousYear}> {"<<"} </button>

                <span className='SmallCalendar-year'>
                    {yearContent}
                </span>
                
                <button className='ButtonSmallCalendar' onClick={nextYear}> {">>"} </button>
            </div>
            <table className='SmallCalendar-table'>
                <thead>
                    <tr>
                        <td className="SmallCalendar-cell-title">L</td>
                        <td className="SmallCalendar-cell-title">M</td>
                        <td className="SmallCalendar-cell-title">M</td>
                        <td className="SmallCalendar-cell-title">J</td>
                        <td className="SmallCalendar-cell-title">V</td>
                        <td className="SmallCalendar-cell-title">S</td>
                        <td className="SmallCalendar-cell-title">D</td>
                    </tr>
                </thead>
                <tbody>
                    {contentCalendar}
                </tbody>
            </table>
        </div>
    )
}