import { useState } from 'react'
import './Exercices.css'
import { Basic } from './Basic'
import { AgeFilter, SupportFilter, CycleFilter, PhysicFilter, TechnicFilter, TacticFilter, MentalFilter } from './Data'
import { timeToString } from './Librairy.js';

export function ExerciceChooserView({ hide, exercices, add }) {

    function ViewSizer() {
        return (
            <ExercicesColonne hide={hide} exercices={exercices} add={add} />
        )
    }

    return (
        <ViewSizer />
    )
}


export function ExercicesColonne({ hide, exercices, add }) {
    const Menu = {
        Exercices: 'Exercices',
        Filters: 'Filters',
        Exercice: 'Exercice'
    };

    const [state, setState] = useState(Menu.Exercices);
    const [exercice, setExercice] = useState(null);

    //const [filtered_exercices, setFilteredExercices] = useState(exercices);

    const [ages, setAges] = useState([]);
    const [cycles, setCycles] = useState([]);
    const [supports, setSupports] = useState([]);
    const [technics, setTechnics] = useState([]);
    const [physics, setPhysics] = useState([]);
    const [tactics, setTactics] = useState([]);
    const [mentals, setMentals] = useState([]);

    //function filter() {
    let filtered_exercices = [];
    exercices.forEach((e) => {
        let ok = true;
        ages.forEach(a => {
            if (e.ages.indexOf(a) === -1) {
                ok = false;
            }
        })

        supports.forEach(a => {
            if (e.supports.indexOf(a) === -1) {
                ok = false;
            }
        })

        cycles.forEach(a => {
            if (e.cycles.indexOf(a) === -1) {
                ok = false;
            }
        })

        technics.forEach(a => {
            if (e.technics.indexOf(a) === -1) {
                ok = false;
            }
        })

        physics.forEach(a => {
            if (e.physics.indexOf(a) === -1) {
                ok = false;
            }
        })

        tactics.forEach(a => {
            if (e.tactics.indexOf(a) === -1) {
                ok = false;
            }
        })

        mentals.forEach(a => {
            if (e.mentals.indexOf(a) === -1) {
                ok = false;
            }
        })

        if (ok) {
            filtered_exercices.push(e);
        }
    })

    function resetFilters() {
        setAges([]);
        setCycles([]);
        setSupports([]);
        setTechnics([]);
        setTactics([]);
        setPhysics([]);
        setMentals([]);
    }

    function selectAge(i) {
        setAges([i])
    }

    function checkAge(i) {
        let a = ages.indexOf(i);
        let t = [...ages];
        if (a < 0) {
            t.push(i);
        } else {
            t.splice(a, 1);
        }
        setAges(t);
    }

    function selectCycle(i) {
        setCycles([i])
    }

    function checkCycle(i) {
        let a = cycles.indexOf(i);
        let t = [...cycles];
        if (a < 0) {
            t.push(i);
        } else {
            t.splice(a, 1);
        }
        setCycles(t);
    }

    function selectSupport(i) {
        setSupports([i])
    }

    function checkSupport(i) {
        let a = supports.indexOf(i);
        let t = [...supports];
        if (a < 0) {
            t.push(i);
        } else {
            t.splice(a, 1);
        }
        setSupports(t);
    }

    function selectTechnic(i) {
        setTechnics([i])
    }

    function checkTechnic(i) {
        let a = technics.indexOf(i);
        let t = [...technics];
        if (a < 0) {
            t.push(i);
        } else {
            t.splice(a, 1);
        }
        setTechnics(t);
    }

    function selectPhysic(i) {
        setPhysics([i])
    }

    function checkPhysic(i) {
        let a = physics.indexOf(i);
        let t = [...physics];
        if (a < 0) {
            t.push(i);
        } else {
            t.splice(a, 1);
        }
        setPhysics(t);
    }

    function selectTactic(i) {
        setTactics([i])
    }

    function checkTactic(i) {
        let a = tactics.indexOf(i);
        let t = [...tactics];
        if (a < 0) {
            t.push(i);
        } else {
            t.splice(a, 1);
        }
        setTactics(t);
    }

    function selectMental(i) {
        setMentals([i])
    }

    function checkMental(i) {
        let a = mentals.indexOf(i);
        let t = [...mentals];
        if (a < 0) {
            t.push(i);
        } else {
            t.splice(a, 1);
        }
        setMentals(t);
    }

    function toExercices() {
        setState(Menu.Exercices);
    }

    function toExercice(exo) {
        setExercice(exo);
        setState(Menu.Exercice);
    }

    function toFilters() {
        setState(Menu.Filters);
    }

    function FiltersView() {
        const iterateAge = (list) => {
            return list.map((e) => (
                <tr key={e.value}>
                    {
                        ages.includes(e.value) ?
                            <td className='ExercicesColonneFilter_cat'><div onClick={() => checkAge(e.value)} className='ExercicesColonneFilter_selected'></div>&nbsp;&nbsp;<span onClick={() => selectAge(e.value)}>{e.label}</span></td>
                            :
                            <td className='ExercicesColonneFilter_cat'><div onClick={() => checkAge(e.value)} className='ExercicesColonneFilter_notselected' ></div>&nbsp;&nbsp;<span onClick={() => selectAge(e.value)}>{e.label}</span></td>
                    }
                </tr>
            ))
        }

        const iterateCycle = (list) => {
            return list.map((e) => (
                <tr key={e.value}>
                    {
                        cycles.includes(e.value) ?
                            <td className='ExercicesColonneFilter_cat'><div onClick={() => checkCycle(e.value)} className='ExercicesColonneFilter_selected'></div>&nbsp;&nbsp;<span onClick={() => selectCycle(e.value)}>{e.label}</span></td>
                            :
                            <td className='ExercicesColonneFilter_cat'><div onClick={() => checkCycle(e.value)} className='ExercicesColonneFilter_notselected' ></div>&nbsp;&nbsp;<span onClick={() => selectCycle(e.value)}>{e.label}</span></td>
                    }
                </tr>
            ))
        }

        const iterateSupport = (list) => {
            return list.map((e) => (
                <tr key={e.value}>
                    {
                        supports.includes(e.value) ?
                            <td className='ExercicesColonneFilter_cat'><div onClick={() => checkSupport(e.value)} className='ExercicesColonneFilter_selected'></div>&nbsp;&nbsp;<span onClick={() => selectSupport(e.value)}>{e.label}</span></td>
                            :
                            <td className='ExercicesColonneFilter_cat'><div onClick={() => checkSupport(e.value)} className='ExercicesColonneFilter_notselected' ></div>&nbsp;&nbsp;<span onClick={() => selectSupport(e.value)}>{e.label}</span></td>
                    }
                </tr>
            ))
        }

        const iterateTechnic = (list) => {
            return list.map((e) => (
                <tr key={e.value}>
                    {
                        technics.includes(e.value) ?
                            <td className='ExercicesColonneFilter_cat'><div onClick={() => checkTechnic(e.value)} className='ExercicesColonneFilter_selected'></div>&nbsp;&nbsp;<span onClick={() => selectTechnic(e.value)}>{e.label}</span></td>
                            :
                            <td className='ExercicesColonneFilter_cat'><div onClick={() => checkTechnic(e.value)} className='ExercicesColonneFilter_notselected' ></div>&nbsp;&nbsp;<span onClick={() => selectTechnic(e.value)}>{e.label}</span></td>
                    }
                </tr>
            ))
        }

        const iteratePhysic = (list) => {
            return list.map((e) => (
                <tr key={e.value}>
                    {
                        physics.includes(e.value) ?
                            <td className='ExercicesColonneFilter_cat'><div onClick={() => checkPhysic(e.value)} className='ExercicesColonneFilter_selected'></div>&nbsp;&nbsp;<span onClick={() => selectPhysic(e.value)}>{e.label}</span></td>
                            :
                            <td className='ExercicesColonneFilter_cat'><div onClick={() => checkPhysic(e.value)} className='ExercicesColonneFilter_notselected' ></div>&nbsp;&nbsp;<span onClick={() => selectPhysic(e.value)}>{e.label}</span></td>
                    }
                </tr>
            ))
        }

        const iterateTactic = (list) => {
            return list.map((e) => (
                <tr key={e.value}>
                    {
                        tactics.includes(e.value) ?
                            <td className='ExercicesColonneFilter_cat'><div onClick={() => checkTactic(e.value)} className='ExercicesColonneFilter_selected'></div>&nbsp;&nbsp;<span onClick={() => selectTactic(e.value)}>{e.label}</span></td>
                            :
                            <td className='ExercicesColonneFilter_cat'><div onClick={() => checkTactic(e.value)} className='ExercicesColonneFilter_notselected' ></div>&nbsp;&nbsp;<span onClick={() => selectTactic(e.value)}>{e.label}</span></td>
                    }
                </tr>
            ))
        }

        const iterateMental = (list) => {
            return list.map((e) => (
                <tr key={e.value}>
                    {
                        mentals.includes(e.value) ?
                            <td className='ExercicesColonneFilter_cat'><div onClick={() => checkMental(e.value)} className='ExercicesColonneFilter_selected'></div>&nbsp;&nbsp;<span onClick={() => selectMental(e.value)}>{e.label}</span></td>
                            :
                            <td className='ExercicesColonneFilter_cat'><div onClick={() => checkMental(e.value)} className='ExercicesColonneFilter_notselected' ></div>&nbsp;&nbsp;<span onClick={() => selectMental(e.value)}>{e.label}</span></td>
                    }
                </tr>
            ))
        }

        return (
            <Basic title="Filtrer" buttons={<>
                <button title="Filtrer les exercices" onClick={resetFilters} ><span className="material-symbols-outlined">replay</span></button>
            </>} hide={toExercices}>
                <tr><td className='ExercicesColonneFilter_title'>Ages : </td></tr>
                {iterateAge(AgeFilter)}
                <tr><td className='ExercicesColonneFilter_title'>Supports : </td></tr>
                {iterateSupport(SupportFilter)}
                <tr><td className='ExercicesColonneFilter_title'>Cycles : </td></tr>
                {iterateCycle(CycleFilter)}
                <tr><td className='ExercicesColonneFilter_title'>Techniques : </td></tr>
                {iterateTechnic(TechnicFilter)}
                <tr><td className='ExercicesColonneFilter_title'>Physiques : </td></tr>
                {iteratePhysic(PhysicFilter)}
                <tr><td className='ExercicesColonneFilter_title'>Tactiques : </td></tr>
                {iterateTactic(TacticFilter)}
                <tr><td className='ExercicesColonneFilter_title'>Mentals : </td></tr>
                {iterateMental(MentalFilter)}
                <tr><td className='ExercicesColonneFilter_void'></td></tr>
            </Basic>
        )
    }


    function ExerciceView({ exo }) {
        const iterateCat = (list, list2) => {
            return list.map((e) => (
                list2.includes(e.value) ?
                    <span key={e.value} className='ExerciceView_cat'>{e.label},</span>
                    :
                    <></>
            ))
        }

        return (
            <Basic title={exo.name} buttons={<>
                <button title="Ajouter l'exercice" onClick={() => {add(exo.name, exo.time, exo.situation)}} ><span className="material-symbols-outlined">add</span></button>
            </>} hide={toExercices}>
                <tr>
                    <td className='ExerciceView_subtitle'>
                        <b>Durée :</b> {timeToString(exo.time)}
                    </td>
                </tr>
                <tr>
                    <td className='ExerciceView_subtitle'>
                        <b>Objectif :</b>
                    </td>
                </tr>
                <tr>
                    <td className='ExerciceView_objectif'>
                        {exo.objectif}
                    </td>
                </tr>
                <tr>
                    <td className='ExerciceView_subtitle'>
                        <b>Catégorie d'age :</b>
                    </td>
                </tr>
                <tr>
                    <td className='ExerciceView_objectif'>
                        {exo.ages.length <= 0 ?
                            <span>Aucune</span>
                            :
                            iterateCat(AgeFilter, exo.ages)
                        }
                    </td>
                </tr>
                <tr>
                    <td className='ExerciceView_subtitle'>
                        <b>Ressource(s) travaillée(s) :</b>
                    </td>
                </tr>
                <tr>
                    <td className='ExerciceView_objectif'>
                        - Physiques :&nbsp; 
                        {exo.physics.length <= 0 ?
                            <span>Aucune</span>
                            :
                            iterateCat(PhysicFilter, exo.physics)
                        }
                    </td>
                </tr>
                <tr>
                    <td className='ExerciceView_objectif'>
                        - Techinques :&nbsp; 
                        {exo.technics.length <= 0 ?
                            <span>Aucune</span>
                            :
                            iterateCat(TechnicFilter, exo.technics)
                        }
                    </td>
                </tr>
                <tr>
                    <td className='ExerciceView_objectif'>
                        - Tactiques :&nbsp; 
                        {exo.tactics.length <= 0 ?
                            <span>Aucune</span>
                            :
                            iterateCat(TacticFilter, exo.tactics)
                        }
                    </td>
                </tr>
                <tr>
                    <td className='ExerciceView_objectif'>
                        - Mentales :&nbsp; 
                        {exo.mentals.length <= 0 ?
                            <span>Aucune</span>
                            :
                            iterateCat(MentalFilter, exo.mentals)
                        }
                    </td>
                </tr>
                <tr>
                    <td className='ExerciceView_subtitle'>
                        <b>Cycle de préparation :</b>
                    </td>
                </tr>
                <tr>
                    <td className='ExerciceView_objectif'>
                        {exo.cycles.length <= 0 ?
                            <span>Aucune</span>
                            :
                            iterateCat(CycleFilter, exo.cycles)
                        }
                    </td>
                </tr>
                <tr>
                    <td className='ExerciceView_subtitle'>
                        <b>Support utilisé :</b>
                    </td>
                </tr>
                <tr>
                    <td className='ExerciceView_objectif'>
                        {exo.supports.length <= 0 ?
                            <span>Aucune</span>
                            :
                            iterateCat(SupportFilter, exo.supports)
                        }
                    </td>
                </tr>

                <tr>
                    <td className='ExerciceView_empty_line'>
                    </td>
                </tr>

                <tr>
                    <td className='ExerciceView_subtitle'>
                        <b>Situation :</b>
                    </td>
                </tr>
                <tr>
                    <td className='ExerciceView_objectif'>
                        <div className='ExerciceView_situation'  dangerouslySetInnerHTML={{ __html: exo.situation }}></div>
                    </td>
                </tr>
                
                <tr><td className='ExerciceRow_void'></td></tr>
            </Basic>
        )
    }

    const iterateExercice = (list) => {
        return list.map((e) => (
            <tr key={e.id}><td className='ExerciceRow_main'>
                <table className='ExerciceRow_table' onClick={() => { toExercice(e) }}>
                    <tbody>
                        <tr>
                            <td className='ExerciceRow_title'>{e.name}</td>
                            <td rowSpan={3} className='ExerciceRow_arrow'><span className="material-symbols-outlined">chevron_right</span></td>
                        </tr>
                        <tr>
                            <td className='ExerciceRow_objectif'>{e.objectif}</td>
                        </tr>
                    </tbody>
                </table>
            </td></tr>
        ))
    }


    if (state === Menu.Filters) {
        return (
            <FiltersView />
        )
    }

    if (state === Menu.Exercice) {
        return (
            <ExerciceView exo={exercice} />
        )
    }

    return (
        <Basic title="Exercices" hide={hide} buttons={
            <button title="Filtrer les exercices" onClick={toFilters} ><span className="material-symbols-outlined">filter_list</span></button>
        } >
            {
                filtered_exercices.length <= 0 ?
                    <tr><td> Pas d'exercices</td></tr>
                    :
                    iterateExercice(filtered_exercices)
            }
            <tr><td className='ExerciceRow_void'></td></tr>

        </Basic>
    )
}