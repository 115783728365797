import { Basic } from './Basic.js';
import './InfoUser.css';
import { db, auth } from "./firebase.js";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";

export function InfoUser({user, hide}) {

    function deleteUser(){
        if (window.confirm('Voulez vous vraiment supprimer cette personne ou ce groupe? (cette action est irréversible)')) {
            let userId = auth.currentUser.uid;
            db.collection("Users").doc(userId).update({ "trainees": firebase.firestore.FieldValue.arrayRemove(user.id) })
            
            db.collection("Trainees").doc(user.id).collection("Cycles").get().then((querySnapshot) => {
                querySnapshot.forEach((doc) => {
                    db.collection("Trainees").doc(user.id).collection("Cycles").doc(doc.id).collection("SubCycles").get().then((querySnapshot2) => {
                        querySnapshot2.forEach((doc2) => {
                            db.collection("Trainees").doc(user.id).collection("Cycles").doc(doc.id).collection("SubCycles").doc(doc2.id).delete().then(() => {
                                console.log("Document successfully deleted!");
                            })
                        });
                    })
                    .finally(() => {
                        db.collection("Trainees").doc(user.id).collection("Cycles").doc(doc.id).delete().then(() => {
                            console.log("Document successfully deleted!");
                        }).catch((error) => {
                            console.error("Error removing document: ", error);
                        });
                     })
                    .catch((error) => {
                        console.log("Error getting documents: ", error);
                    });
                });
            }).finally(() => {
                db.collection("Trainees").doc(user.id).delete().then(() => {
                    console.log("Document successfully deleted!");
                }).catch((error) => {
                    console.error("Error removing document: ", error);
                });
            });

        } else {
            
        }
    }

    return (
        <Basic title={user.name} hide={hide} buttons={
            <>
                <button onClick={() => deleteUser()}><span className="material-symbols-outlined">delete</span></button>
                <button><span className="material-symbols-outlined">edit</span></button>
            </>
        }>
            <tr><td></td></tr>
        </Basic>
    )
}